// import React, { useEffect, useState } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import { setaccount } from '../Redux/Accounts'
// import WalletConnectProvider from '@walletconnect/web3-provider'
// import Web3Modal from 'web3modal'
// import { IoWallet } from "react-icons/io5"
// import { providers } from "ethers"
// import Change from '../Common/StringToSub'
// import { BasicInfo, toastFailed } from '../Config/BasicInfo'
// const ConnectButton = () => {
//     const [web3Modal, setWeb3Modal] = useState(null)
//     const acc = useSelector((state) => state.account.value)
//     const dispatch = useDispatch()

//     const checkWalletIsConnected = () => {
//         const { ethereum } = window
//         if (!ethereum) {
//             console.log("Make sure you have MetaMask installed")
//             toastFailed("Make sure you have MetaMask installed")
//             return
//         }
//     }

//     useEffect(() => {
//         checkWalletIsConnected()
//     }, [])

//     useEffect(() => {
//         const providerOptions = {
//             walletconnect: {
//                 package: WalletConnectProvider,
//                 options: {
//                     infuraId: "05f311673625f063cd5c0736f5bb17b0",
//                 }
//             },
//         }

//         const newWeb3Modal = new Web3Modal({
//             cacheProvider: true,
//             providerOptions,
//         })

//         setWeb3Modal(newWeb3Modal)
//     }, [])

//     useEffect(() => {
//         if (web3Modal && web3Modal.cachedProvider) {
//             connectWallet()
//         }
//     }, [web3Modal])

//     async function connectWallet() {
//         try {
//             const provider = await web3Modal.connect()
//             addListeners(provider)
//             const ethersProvider = new providers.Web3Provider(provider)
//             const userAddress = await ethersProvider.getSigner().getAddress()
//             dispatch(setaccount(userAddress))
//         } catch (error) {
//             console.error("Failed to connect wallet:", error)
//             toastFailed("Failed to connect wallet")
//         }
//     }

//     function addListeners(provider) {
//         provider.on("accountsChanged", (accounts) => {
//             window.location.reload()
//         })
//         provider.on("chainChanged", () => {
//             window.location.reload()
//         })
//     }

//     function linkBlockchain() {
//         window.open(BasicInfo?.testnetLink + acc)
//     }

//     return (
//         <>
//             <p style={{ display: "none" }} id='connectButtonAddressCopy'>{acc}</p>
//             {
//                 acc != null ?
//                     <span id="connectButtonAddress" onClick={linkBlockchain} style={{ cursor: "pointer" }}><i><IoWallet /></i>{Change(acc)}
//                     </span> :
//                     <button onClick={connectWallet} className="group py-3 px-6 md:px-8 w-auto btnMU mx-auto flex items-center justify-center space-x-2 transition duration-200 login-btn" id="login-btn"><span><span>Connect</span></span>
//                         <div className="w-3.5 h-3.5 border-2 text-blue-400 text-4xl animate-spin border-gray-300 hidden group-active:flex group-focus:flex items-center justify-center border-t-blue-400 rounded-full">
//                         </div>
//                     </button>
//             }
//         </>
//     )
// }
// export default ConnectButton

// import React, { useEffect, useState } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import { setaccount } from '../Redux/Accounts'
// import WalletConnectProvider from '@walletconnect/web3-provider'
// import Web3Modal from 'web3modal'
// import { IoWallet } from "react-icons/io5"
// import { providers } from "ethers"
// import Change from '../Common/StringToSub'
// import { BasicInfo, toastSuccess, toastFailed } from '../Config/BasicInfo'

// const ConnectButton = () => {
//     const [web3Modal, setWeb3Modal] = useState(null)
//     const acc = useSelector((state) => state.account.value)
//     const dispatch = useDispatch()
//     const ReqChainId = BasicInfo.ChainId;

//     const checkWalletIsConnected = () => {
//         if (!window.ethereum) {
//             toastFailed("Make sure you have MetaMask installed"); 
//             return;
//         }
//     }

//     useEffect(() => {
//         checkWalletIsConnected()
//     }, [])

//     useEffect(() => {
//         const providerOptions = {
//             walletconnect: {
//                 package: WalletConnectProvider,
//                 options: {
//                     infuraId: "05f311673625f063cd5c0736f5bb17b0",
//                 }
//             },
//         }

//         const newWeb3Modal = new Web3Modal({
//             cacheProvider: true,
//             providerOptions,
//         })

//         setWeb3Modal(newWeb3Modal)
//     }, [])

//     useEffect(() => {
//         if (web3Modal && web3Modal.cachedProvider) {
//             connectWallet()
//         }
//     }, [web3Modal])

//     async function connectWallet() {
//         try {
//             const provider = await web3Modal.connect();
//             addListeners(provider);
//             const ethersProvider = new providers.Web3Provider(provider);
//             const { chainId } = await ethersProvider.getNetwork();
//             BasicInfo.isDebug && console.log("Current Chain ID:", chainId);

//             // Switch to required chain only if connected to the desired one
//             if (chainId !== ReqChainId) {
//                 try {
//                     await provider.request({
//                         method: 'wallet_switchEthereumChain',
//                         params: [{ chainId: `0x${ReqChainId.toString(16)}` }],
//                     });
//                     BasicInfo.isDebug &&console.log("Switched to Polygon Network.");
//                 } catch (switchError) {
//                     toastFailed("Connect to Polygon Network to use this site")
//                     BasicInfo.isDebug && console.error("Failed to switch to Polygon:", switchError);
//                     // Handle case where the user rejects the request
//                     return; // Do not connect if user cancels
//                 }
//             }
//             const userAddress = await ethersProvider.getSigner().getAddress();
//             dispatch(setaccount(userAddress));
//             BasicInfo.isDebug && console.log("Connected account:", userAddress);
//         } catch (error) {
//             BasicInfo.isDebug && console.error("Failed to connect wallet:", error);
//         }
//     }

//     function addListeners(provider) {
//         provider.on("accountsChanged", () => window.location.reload());
//     }

//     function linkBlockchain() {
//         window.open(`${BasicInfo?.testnetLink}${acc}`);
//     }

//     return (
//         <>
//             <p style={{ display: "none" }} id='connectButtonAddressCopy'>{acc}</p>
//             {
//                 acc ? (
//                     <span id="connectButtonAddress" onClick={linkBlockchain} style={{ cursor: "pointer" }}>
//                         <i><IoWallet /></i>{Change(acc)}
//                     </span>
//                 ) : (
//                     <button onClick={connectWallet} className="group py-3 px-6 md:px-8 w-auto btnMU mx-auto flex items-center justify-center space-x-2 transition duration-200 login-btn" id="login-btn">
//                         <span>Connect</span>
//                         <div className="w-3.5 h-3.5 border-2 animate-spin hidden group-active:flex group-focus:flex items-center justify-center border-t-blue-400 rounded-full" />
//                     </button>
//                 )
//             }
//         </>
//     )
// }
// export default ConnectButton


import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setaccount } from '../Redux/Accounts'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
import { IoWallet } from "react-icons/io5"
import { providers } from "ethers"
import Change from '../Common/StringToSub'
import { BasicInfo, toastSuccess, toastFailed } from '../Config/BasicInfo'
import { useNavigate } from 'react-router-dom'

const ConnectButton = () => {
    const [web3Modal, setWeb3Modal] = useState(null)
    const acc = useSelector((state) => state.account.value)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ReqChainId = BasicInfo.ChainId

    const checkWalletIsConnected = () => {
        if (!window.ethereum) {
            toastFailed("Make sure you have MetaMask installed"); 
            return;
        }
    }

    useEffect(() => {
        checkWalletIsConnected()
    }, [])

    useEffect(() => {
        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    infuraId: "05f311673625f063cd5c0736f5bb17b0",
                }
            },
        }

        const newWeb3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions,
        })

        setWeb3Modal(newWeb3Modal)
    }, [])

    useEffect(() => {
        if (web3Modal && web3Modal.cachedProvider) {
            connectWallet()
        }
    }, [web3Modal])

    async function connectWallet() {
        try {
            const provider = await web3Modal.connect()
            addListeners(provider)
            const ethersProvider = new providers.Web3Provider(provider)
            const { chainId } = await ethersProvider.getNetwork()
            BasicInfo.isDebug && console.log("Current Chain ID:", chainId)

            if (chainId !== ReqChainId) {
                try {
                    await provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: `0x${ReqChainId.toString(16)}` }],
                    })
                    BasicInfo.isDebug && console.log("Switched to Polygon Network.")
                } catch (switchError) {
                    toastFailed("Connect to Polygon Network to use this site")
                    BasicInfo.isDebug && console.error("Failed to switch to Polygon:", switchError)
                    return
                }
            }

            const userAddress = await ethersProvider.getSigner().getAddress()
            dispatch(setaccount(userAddress))
            BasicInfo.isDebug && console.log("Connected account:", userAddress)
        } catch (error) {
            BasicInfo.isDebug && console.error("Failed to connect wallet:", error)
        }
    }

    function addListeners(provider) {
        provider.on("accountsChanged", () => window.location.reload())

        provider.on("chainChanged", async (chainId) => {
            if (parseInt(chainId, 16) !== ReqChainId) {
                toastFailed("Switching networks is not allowed. Disconnecting and redirecting.")
                await disconnectWallet();
                navigate('/')
                await localStorage.clear();
            }
        })
    }

    async function disconnectWallet() {
        if (web3Modal) {
            await web3Modal.clearCachedProvider()  // Clears the cache to disconnect the wallet
        }
        dispatch(setaccount(null))  // Reset the account state in Redux
    }

    function linkBlockchain() {
        window.open(`${BasicInfo?.testnetLink}${acc}`)
    }

    return (
        <>
            <p style={{ display: "none" }} id='connectButtonAddressCopy'>{acc}</p>
            {
                acc ? (
                    <span id="connectButtonAddress" onClick={linkBlockchain} style={{ cursor: "pointer" }}>
                        <i><IoWallet /></i>{Change(acc)}
                    </span>
                ) : (
                    <button onClick={connectWallet} className="group py-3 px-6 md:px-8 w-auto btnMU mx-auto flex items-center justify-center space-x-2 transition duration-200 login-btn" id="login-btn">
                        <span>Connect</span>
                        <div className="w-3.5 h-3.5 border-2 animate-spin hidden group-active:flex group-focus:flex items-center justify-center border-t-blue-400 rounded-full" />
                    </button>
                )
            }
        </>
    )
}

export default ConnectButton
