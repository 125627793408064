import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import useAxiosHelper from '../../Common/AxiosHalper';
import { ApiPaths } from '../../Config/ApiPaths';
import Change from '../../Common/StringToSub';
import moment from 'moment';
import Select from 'react-select';
import Loader from '../../Components/Loader/Loader';
import DatePicker from 'react-datepicker';
import Pagination from '../../Components/PaginationControls/PaginationControls';
import { toastSuccess } from '../../Config/BasicInfo';
export default function MyDirects() {
    const [loading, setLoading] = useState(false);
    const [teamData, setTeamData] = useState([]);
    const { AxiosPost } = useAxiosHelper([]);
    const [search, setSearch] = useState({
        search: '',
        name: '',
        username: '',
        startDateJoining: '',
        endDateJoining: '',
        status: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearch((prevSearch) => ({
            ...prevSearch,
            [name]: value,
        }));
    };
    const handleDateChange = (date, type) => {
        setSearch((prevSearch) => ({
            ...prevSearch,
            [type]: date,
        }));
    };
    const [isResetting, setIsResetting] = useState(false);
    const resetFilters = () => {
        setSearch({
            search: '',
            name: '',
            username: '',
            startDateJoining: '',
            endDateJoining: '',
            status: '',
        });
        setCurrentPage(1);
        setIsResetting(true);
    };
    // useEffect to trigger FetchData when isResetting changes
    // useEffect(() => {
    //     if (isResetting) {
    //         FetchData();
    //         setIsResetting(false); // Reset the flag
    //     }
    // }, [isResetting]);
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#000',
            borderColor: '#00ffff',
            color: '#00ffff',
            boxShadow: state.isFocused ? '0 0 5px #00ffff' : 'none',
            '&:hover': {
                borderColor: '#00ffff',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#000',
            color: '#00ffff',
            borderRadius: '4px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#00ffff' : state.isFocused ? '#333' : '#000',
            color: state.isSelected ? '#000' : '#00ffff',
            '&:hover': {
                backgroundColor: '#333',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#00ffff',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#00ffff',
            '&:hover': {
                color: '#00ffff',
            },
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: '#00ffff',
        }),
    };
    let x = true;
    useEffect(() => {
        // if (x === true) {
        FetchData();
        // x = false
        // }
    }, [currentPage])
    const limit = 10;

    async function FetchData() {
        try {
            setLoading(true);
            let userId = localStorage.getItem("userId");
            console.log("uId:", userId);
            const queryParams = new URLSearchParams({
                page: currentPage,
                // limit: limit,
            });
            const queryString = queryParams.toString();
            const body = {
                uid: userId,
            }
            console.log("body:", body);
            const tempTeamData = await AxiosPost(`${ApiPaths.getLevelTeam}?${queryString}`, body)
            console.log("teamData", tempTeamData);
            setTeamData(tempTeamData);
            if (tempTeamData) {
                toastSuccess(tempTeamData?.message);
                setTotalPages(tempTeamData?.totalPages || 1);
            }
        } catch (e) {
            console.error("Error fetching data", e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && <Loader />}
            <div id="loading" className="bg-gradient-to-b from-black to-black w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full flex items-center justify-center z-50" style={{ background: 'black', display: 'none' }}>
                {/* <img src="images/logoname.webp" width="175" height="65"  class="mx-auto h-40 w-auto"> */}
                <div className="fancy-spinner">
                    <div className="ring" />
                    <div className="ring" />
                    <div className="dot">
                        <img src="images/DeFi_Coin.webp?v=1720155640" width="150px" height="150px" className="h-full w-full object-contain" alt="Defi World Logo" />
                    </div>
                </div>
            </div>
            <div id="mobile" className="demo2 relative">
                <div id="mobileBodyContent">
                    <Header />
                    <div className="inner-content px-4 2xl:px-2 mx-auto mt-5 pb-20 overflow-hidden">
                        <section className="font-normal bg-darkBlueGray-500 text-white mt-0">
                            <div className="container mx-auto">
                                <div className="flex flex-wrap w-full justify-center">
                                    <div className="contentBox w-full relative">
                                        <div className="mb-8 p-4 md:p-6 rounded-md bg-stone-700 bg-opacity-10 border border-[#00ffff] relative shadow-inner">
                                            <div className="title text-lg font-medium text-white mb-3 tracking-wider textdefibold">My Directs</div>
                                            <div className="flex items-center justify-between mb-5">
                                            </div>
                                            <div className="overflow-x-auto">
                                                <div className="inline-block min-w-full rounded-5xl overflow-hidden ">
                                                    <table className="table-auto w-full p-0 dataTable no-footer" id="myTeamLeft">
                                                        <thead>
                                                            <tr className="text-body">
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left rounded-tl-xl rounded-bl-xl">Sr.</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Member Code</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Sponsor</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Joining Date</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Activation Date</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Activation amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                teamData?.data?.length > 0 ? teamData?.data?.map((x, i) => {
                                                                    return <tr key={x?.uid || i}>
                                                                        {/* <td>{i + 1}</td> */}
                                                                        <td>{(currentPage - 1) * limit + i + 1}</td>
                                                                        <td>{Change(x?.username)}</td>
                                                                        <td>{Change(x?.sponsor_username)}</td>
                                                                        <td>{x?.joining_date ? moment(x?.joining_date).format("MMM Do YY") : '-'}</td>
                                                                        <td>{x?.Activation_date ? moment(x?.Activation_date).format("MMM Do YY") : '-'}</td>
                                                                        <td>{x?.selfInvestmentValue}</td>
                                                                    </tr>
                                                                }) : <tr>
                                                                    <td className="text-sm text-center px-4 py-3" colSpan={7}>No Data Found.</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    {/* Main modal */}
                    <div id="teamdefaultModal" tabIndex={-1} aria-hidden="true" className="modelTable fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full justify-center items-center">
                        <div className="relative w-full max-w-2xl max-h-full">
                            {/* Modal content */}
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 modelTableInner">
                                {/* Modal header */}
                                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white text-center w-full">
                                        Packages
                                    </h3>
                                    <button type="button" data-modal-hide="teamdefaultModal" data-modal-toggle="teamdefaultModal" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                {/* Modal body */}
                                <div className="overflow-x-auto">
                                    <div className="inline-block min-w-full rounded-5xl overflow-hidden ">
                                        <table className="table-auto w-full">
                                            <thead>
                                                <tr className="text-body">
                                                    <th className="h-auto px-4 py-3 bg-[#00ffff] bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Sr.</th>
                                                    <th className="h-auto px-4 py-3 bg-[#00ffff] bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Amount</th>
                                                    <th className="h-auto px-4 py-3 bg-[#00ffff] bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody id="tableBody">
                                                <tr className="border-b border-gray-100 border-opacity-10 font-medium text-xs tracking-wider whitespace-pre text-white">
                                                    <td className="text-left px-4 py-3 text-white">id</td>
                                                    <td className="text-left px-4 py-3 text-white">20-05-2023</td>
                                                    <td className="text-left px-4 py-3 text-white">20-05-2023</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>)
}
