import React from 'react'
import DashBoard from './Pages/DashBoard/DashBoard'
import Genealogy from './Pages/Genealogy/Genealogy'
import Material from './Pages/Materials/Materials'
import Login from './Pages/Login/Login'
import IncomeOverview from './Pages/IncomeOverview/IncomeOverview'
import Packages from './Pages/Packages/Packages'
import Withdraws from './Pages/Withdraws/Withdraws'
import MyDirects from './Pages/MyDirects/MyDirects'
import MyTeam from './Pages/MyTeam/MyTeam'
import SupportTickets from './Pages/SupportTickets/SupportTickets'
import './Common.css'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Register from './Pages/Register/Register'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
export default function App() {
  return (
    <>
      <ToastContainer position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/DashBoard" element={<DashBoard />} />
          <Route path="/IncomeOverview" element={<IncomeOverview />} />
          <Route path="/Packages" element={<Packages />} />
          <Route path="/Genealogy" element={<Genealogy />} />
          <Route path="/Withdraws" element={<Withdraws />} />
          <Route path="/MyDirects" element={<MyDirects />} />
          <Route path="/MyTeam" element={<MyTeam />} />
          <Route path="/Help" element={<SupportTickets />} />
          <Route path="/Material" element={<Material />} />
        </Routes>
      </BrowserRouter>
    </>

  )
}
