import { toast } from "react-toastify";
export const BasicInfo = {
    // logo: Logo,
    // logoIcon:LogoIcon,
    projectName:"Defi Affiliate",
    isDebug:true,
    coinName: "DWC",
    providerLink: "https://polygon-rpc.com/",
    privateKey:
    "71a348e99a6b290dc2e3ce937fe96dbe3b39aeb62624df693d7a35e66f2753c8",
  // websiteLink: "https://nxtbot.io/",
  testnetLink: "https://polygonscan.com/address/",
  ChainId:137,
  tokenPrice : 0.7491

}
export const toastSuccess = (msg) => toast.success(msg);
export const toastFailed = (msg) => toast.error(msg);