import React from 'react'
import DefiCoin from './../../images/DeFi_Coin.webp'
import Arrow from './../../images/arrow.svg'
import BrandLogo from './../../images/logo-brand.gif'
import IncomeOverview from "./../../images/iconss/income-overview.webp"
import Packege from "./../../images/iconss/packege.webp"
import MyDirects from "./../../images/iconss/my-direct.webp"
import MyTeam from "./../../images/iconss/my-team.webp"
import Material from "./../../images/iconss/materials.webp"
import Help from "./../../images/iconss/help.webp"
import Genealogy from "./../../images/iconss/genealogy.webp"
import Withdraws from "./../../images/iconss/withdraw.webp"
import { LiaNetworkWiredSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { LiaHomeSolid } from "react-icons/lia";
import { LiaSignOutAltSolid } from "react-icons/lia";
import { LiaBookSolid } from "react-icons/lia";
import { LiaFileSolid } from "react-icons/lia";
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'


export default function SupportTickets() {
    return (
        <div>
       
            <div id="loading" className="bg-gradient-to-b from-black to-black w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full flex items-center justify-center z-50" style={{ background: 'black', display: 'none' }}>
                <div className="fancy-spinner">
                    <div className="ring" />
                    <div className="ring" />
                    <div className="dot">
                        <img src={DefiCoin} width="150px" height="150px" className="h-full w-full object-contain" alt="Defi World Logo" />
                    </div>
                </div>
            </div>

            <div id="mobile" className="demo2 relative">
                <div id="mobileBodyContent">
                    <Header/>
                    {/*  */}
                    <div className="inner-content px-4 2xl:px-0 mx-auto mt-5 pb-20">
                        <section className="font-normal bg-darkBlueGray-500 text-white mt-0">
                            <div className="container mx-auto">
                                <div className="flex flex-wrap justify-center">
                                    <div className="contentBox">
                                        <div className="mb-8 p-4 md:p-6 rounded-md bg-stone-700 bg-opacity-10 border border-[#006f76] relative shadow-inner">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute -bottom-[7%] sm:-bottom-[8%]  xl:-bottom-[8%] -left-[35px] h-[200px] filter-light" xmlnsXlink="http://www.w3.org/1999/xlink" id="Layer_2" data-name="Layer 2" viewBox="0 0 105.51 153.94">
                                                <defs>
                                                    <style dangerouslySetInnerHTML={{ __html: "\n                                    .cls-1 {\n                                        fill: #006f76;\n                                    }\n\n                                    .cls-1,\n                                    .cls-2,\n                                    .cls-3 {\n                                        stroke-width: 0px;\n                                    }\n\n                                    .cls-2 {\n                                        fill: #01ffff;\n                                    }\n\n                                    .cls-3 {\n                                        fill: none;\n                                    }\n\n                                    .cls-4 {\n                                        clip-path: url(#clippath-1);\n                                    }\n\n                                    .cls-5 {\n                                        clip-path: url(#clippath);\n                                    }\n                                " }} />
                                                    <clipPath id="clippath">
                                                        <path className="cls-3" d="m4.28,0l101.23,5.1-11.88,134.5S.47,155.28,0,153.85" />
                                                    </clipPath>
                                                    <clipPath id="clippath-1">
                                                        <polygon className="cls-3" points="23.76 -104.68 159.21 -104.68 79.21 127.71 23.76 125.81 23.76 -104.68" />
                                                    </clipPath>
                                                </defs>
                                                <g id="Layer_2-2" data-name="Layer 2">
                                                    <g className="cls-5">
                                                        <g className="cls-4">
                                                            <polygon className="cls-2" points="61.8 121.36 34.88 121.36 34.88 113.78 66.99 113.78 61.8 121.36" />
                                                            <polygon className="cls-2" points="27.3 74.65 27.3 121.36 34.88 121.36 34.88 69.45 27.3 74.65" />
                                                            <polygon className="cls-2" points="31.09 32.72 31.09 78.84 34.88 78.84 34.88 30.12 31.09 32.72" />
                                                            <path className="cls-1" d="m522.34,114.74H33.92V-86.84h488.42V114.74Zm-486.49-1.93h484.56V-84.91H35.85V112.81Z" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute -top-[7%] sm:-top-[8%]  -right-[35px] h-[200px] filter-light" style={{ transform: 'rotate(180deg)' }} xmlnsXlink="http://www.w3.org/1999/xlink" id="Layer_2" data-name="Layer 2" viewBox="0 0 105.51 153.94">
                                                <defs>
                                                    <style dangerouslySetInnerHTML={{ __html: "\n                                    .cls-1 {\n                                        fill: #006f76;\n                                    }\n\n                                    .cls-1,\n                                    .cls-2,\n                                    .cls-3 {\n                                        stroke-width: 0px;\n                                    }\n\n                                    .cls-2 {\n                                        fill: #01ffff;\n                                    }\n\n                                    .cls-3 {\n                                        fill: none;\n                                    }\n\n                                    .cls-4 {\n                                        clip-path: url(#clippath-1);\n                                    }\n\n                                    .cls-5 {\n                                        clip-path: url(#clippath);\n                                    }\n                                " }} />
                                                    <clipPath id="clippath">
                                                        <path className="cls-3" d="m4.28,0l101.23,5.1-11.88,134.5S.47,155.28,0,153.85" />
                                                    </clipPath>
                                                    <clipPath id="clippath-1">
                                                        <polygon className="cls-3" points="23.76 -104.68 159.21 -104.68 79.21 127.71 23.76 125.81 23.76 -104.68" />
                                                    </clipPath>
                                                </defs>
                                                <g id="Layer_2-2" data-name="Layer 2">
                                                    <g className="cls-5">
                                                        <g className="cls-4">
                                                            <polygon className="cls-2" points="61.8 121.36 34.88 121.36 34.88 113.78 66.99 113.78 61.8 121.36" />
                                                            <polygon className="cls-2" points="27.3 74.65 27.3 121.36 34.88 121.36 34.88 69.45 27.3 74.65" />
                                                            <polygon className="cls-2" points="31.09 32.72 31.09 78.84 34.88 78.84 34.88 30.12 31.09 32.72" />
                                                            <path className="cls-1" d="m522.34,114.74H33.92V-86.84h488.42V114.74Zm-486.49-1.93h484.56V-84.91H35.85V112.81Z" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <div className="title text-lg font-medium text-white mb-5 tracking-wider textdefibold">Support Tickets</div>
                                            <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
                                                <div className="col-span-1 lg:col-span-4">
                                                    <form action="https://defiworld.ai/process-support-tickets" className="overflow-x-auto max-h-[458px] overflow-y-auto p-3 md:p-6 rounded-md  border border-[#006f76] relative shadow-inner mb-5 h-full" encType="multipart/form-data" method="POST">
                                                        <input type="hidden" name="_method" defaultValue="POST" />
                                                        <input type="hidden" name="_token" defaultValue="ZQgr4WS4CxntP05HYmChoPLxwhY8nA1XI66hfajP" />                                    <h3 className="mb-3 text-lg text-white font-bold font-heading text-center">Create a support ticket</h3>
                                                        <div className="flex items-center pl-6 mb-3 rounded-md border border-[#006f76]">
                                                            <span className="inline-block pr-3 py-2 border-r-0 border-gray-300 border-opacity-10">
                                                                <i className=" text-[#006f76] text-xl">
                                                                <LiaBookSolid />
                                                                </i>
                                                            </span>
                                                            <input className="w-full bg-transparent	text-gray-300 pl-1 pr-6 py-4 font-normal placeholder-gray-200 placeholder-opacity-40 rounded-r-md appearance-none border-0 focus:outline-none focus:ring-0" type="text" placeholder="Enter subject here" name="subject" required="required" />
                                                        </div>
                                                        <div className="flex items-center pl-6 mb-3 border border-[#006f76] rounded-md">
                                                            <span className="inline-block pr-3 py-2 border-r-0 border-gray-300 border-opacity-10">
                                                                <i className=" text-[text-[#006f76] ] text-xl"><LiaFileSolid /></i>
                                                            </span>
                                                            <input className="w-full bg-transparent text-gray-300 pl-1 pr-6 py-4 font-normal placeholder-gray-200 placeholder-opacity-40 rounded-r-md appearance-none border-0 focus:outline-none focus:ring-0" type="file" placeholder="Enter subject here" name="file" required="required" />
                                                        </div>
                                                        <div className="flex items-center pl-6 mb-3 bg-transparent border border-[#006f76] rounded-md overflow-hidden">
                                                            <textarea className="w-full bg-transparent	text-gray-300 pl-1 pr-6 py-4 font-normal placeholder-gray-200 placeholder-opacity-40 rounded-r-md appearance-none resize-none border-0 focus:outline-none focus:ring-0" placeholder="Enter Message here" name="message" required="required" rows={4} defaultValue={""} />
                                                        </div>
                                                        <button className="bg-gradient-to-br from-primary/90 to-primary/60 rounded-md py-3 text-sm w-full hover:bg-secondary text-white transition duration-200" id="registerNowButton" type="submit" name="submit">Create Ticket</button>
                                                    </form>
                                                </div>
                                                <div className="col-span-1 lg:col-span-8">
                                                    <div className="overflow-x-auto max-h-[458px] overflow-y-auto">
                                                        <div className="inline-block min-w-full rounded- overflow-hidden">
                                                            <table className="table-auto w-full text-white">
                                                                <thead>
                                                                    <tr className="text-body">
                                                                        <th className="h-auto px-4 py-3 bg-[#ffffff17] backdrop-blur-md text-sm font-normal whitespace-pre text-white tracking-wider text-left">Ticket Id.</th>
                                                                        <th className="h-auto px-4 py-3 bg-[#ffffff17] backdrop-blur-md text-sm font-normal whitespace-pre text-white tracking-wider text-left">Subject</th>
                                                                        <th className="h-auto px-4 py-3 bg-[#ffffff17] backdrop-blur-md text-sm font-normal whitespace-pre text-white tracking-wider text-left">Message</th>
                                                                        <th className="h-auto px-4 py-3 bg-[#ffffff17] backdrop-blur-md text-sm font-normal whitespace-pre text-white tracking-wider text-left">Attachment</th>
                                                                        <th className="h-auto px-4 py-3 bg-[#ffffff17] backdrop-blur-md text-sm font-normal whitespace-pre text-white tracking-wider text-left">Status</th>
                                                                        <th className="h-auto px-4 py-3 bg-[#ffffff17] backdrop-blur-md text-sm font-normal whitespace-pre text-white tracking-wider text-left">Reply</th>
                                                                        <th className="h-auto px-4 py-3 bg-[#ffffff17] backdrop-blur-md text-sm font-normal whitespace-pre text-white tracking-wider text-left">Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="text-sm text-center px-4 py-3" colSpan={8}>No Tickets Found.</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <Footer/>
            {/*  */}
            {/* Tailwind CDN CSS */}
            {/*  */}
            <grammarly-desktop-integration data-grammarly-shadow-root="true" />
        </div>)
}
