import { BasicInfo, toastFailed } from "../Config/BasicInfo";
import ContractDetails from "../Contracts/ContractDetails";
import { ethers } from 'ethers';
export default async function USDTIncreaseAllowance(amount,token) {
        try {
            const { ethereum } = window;
            if (ethereum) {
                // Use BigNumber from ethers to handle large numbers safely
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();

                const busdInstance = new ethers.Contract(
                    token,
                    ContractDetails.BUSD_ABI,
                    signer
                );
                const decimals = await busdInstance.decimals();
                console.log(decimals,"decimals")
                const newAmount = ethers.utils.parseUnits(amount.toString(), decimals);

                let inc = await busdInstance.increaseAllowance(
                    ContractDetails.contract,
                    amount,
                    { value: ethers.utils.parseEther("0") }
                );
                await inc.wait();
                BasicInfo.isDebug && console.log("Tr Hash 1: " + inc.hash);
                return true;
            }
        } catch (error) {
            toastFailed("Transaction Failed.");
            BasicInfo.isDebug && console.log("error11", error);
            return false;
        }
    }