import React from 'react'

const ValueCardDiv = ({ logoImage, title, value }) => {
    return (
        <div className="px-3 py-4 rounded-md w-80 h-auto bg-opacity-10 relative mb-2 defiboxIn flex justify-between items-center adxy">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_2"
                data-name="Layer 2"
                viewBox="0 0 698.32 236.61"
                className="absolute h-auto left-0 top-0 w-full cardglow"
            >
                <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                        <g>
                            <polygon
                                className="cls-3"
                                points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75"
                            />
                            <polygon
                                className="cls-3"
                                points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75"
                            />
                            <polygon
                                className="cls-3"
                                points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45"
                            />
                        </g>
                    </g>
                    <polygon
                        className="cls-3"
                        points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83"
                    />
                    <path
                        className="cls-3"
                        d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z"
                    />
                    <polygon
                        className="cls-3"
                        points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4"
                    />
                    <g>
                        <rect
                            className="cls-3"
                            x="616.26"
                            y="29.95"
                            width="10.82"
                            height="9.23"
                            transform="translate(656.23 -587.1) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="598.53"
                            y="29.95"
                            width="10.82"
                            height="9.23"
                            transform="translate(638.5 -569.37) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="580.8"
                            y="29.95"
                            width="10.82"
                            height="9.23"
                            transform="translate(620.78 -551.65) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="563.08"
                            y="29.95"
                            width="10.82"
                            height="9.23"
                            transform="translate(603.05 -533.92) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="545.35"
                            y="29.95"
                            width="10.82"
                            height="9.23"
                            transform="translate(585.32 -516.19) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="527.62"
                            y="29.95"
                            width="10.82"
                            height="9.23"
                            transform="translate(567.6 -498.47) rotate(90)"
                        />
                    </g>
                    <g>
                        <rect
                            className="cls-3"
                            x="365.72"
                            y="190.41"
                            width="10.82"
                            height="9.23"
                            transform="translate(566.16 -176.09) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="347.99"
                            y="190.41"
                            width="10.82"
                            height="9.23"
                            transform="translate(548.43 -158.37) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="330.26"
                            y="190.41"
                            width="10.82"
                            height="9.23"
                            transform="translate(530.7 -140.64) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="312.54"
                            y="190.41"
                            width="10.82"
                            height="9.23"
                            transform="translate(512.98 -122.91) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="294.81"
                            y="190.41"
                            width="10.82"
                            height="9.23"
                            transform="translate(495.25 -105.19) rotate(90)"
                        />
                        <rect
                            className="cls-3"
                            x="277.08"
                            y="190.41"
                            width="10.82"
                            height="9.23"
                            transform="translate(477.52 -87.46) rotate(90)"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-3"
                            d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                        <path
                            className="cls-3"
                            d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                        <path
                            className="cls-3"
                            d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                        <path
                            className="cls-3"
                            d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                        <path
                            className="cls-3"
                            d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-3"
                            d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                        <path
                            className="cls-3"
                            d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                        <path
                            className="cls-3"
                            d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                        <path
                            className="cls-3"
                            d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                        <path
                            className="cls-3"
                            d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                        />
                    </g>
                    <polygon
                        className="cls-3"
                        points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65"
                    />
                </g>
            </svg>
            <div className="icon-box mb-8 relative z-10">
                <img
                    src={logoImage}
                    width={48}
                    height={48}
                    className="h-12 w-12 max-h-12 max-w-12 logo-glow"
                    alt="sponser"
                />
            </div>
            <div className="text-left relative z-10 pr-3">
                <h4 className="name text-sm text-white text-opacity-80 mb-1 text-center">
                    {title}
                </h4>
                <div className="mdt text-base text-white font-medium tracking-wider text-center">
                    {value ?? 0}
                </div>
            </div>
        </div>
    )
}

export default ValueCardDiv