import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import DefiCoin from "./../../images/DeFi_Coin.webp";
import PendingWithdraw from "./../../images/icons/pending-withdraw.webp";
import Availablbalance from "./../../images/icons/available-balance.webp";
import DailyBouns from "./../../images/icons/daily-bonus.webp";
import LevelBonus from "./../../images/icons/level-bonus.webp";
import BoosterBonus from "./../../images/icons/booster-bonus.webp";
import RoyalityBonus from "./../../images/icons/royalty-bonus.webp";
import TotalWithdraw from "./../../images/icons/total-withdraw.webp";
import { Link } from 'react-router-dom';
import { BasicInfo, toastFailed, toastSuccess } from '../../Config/BasicInfo';
import useAxiosHelper from '../../Common/AxiosHalper';
import { ApiPaths } from '../../Config/ApiPaths';
import moment from 'moment/moment';
import Pagination from '../../Components/PaginationControls/PaginationControls';
export default function Withdraws() {
    const [formAmount, setFormAmount] = useState();
    const [formFee, setFormFee] = useState();
    const [withdrawFee, setWithdrawFee] = useState();
    const [finalAmount, setFinalAmount] = useState();
    const [walletData, setWalletData] = useState();
    const [availableBalance, setAvailableBalance] = useState();
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [paymentTransaction, setPaymentTransaction] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const { AxiosGet, AxiosPost } = useAxiosHelper([]);

    const handleAmountChnage = (amount) => {
        const numericAmount = parseFloat(amount);
        setFormAmount(numericAmount);
        if (isNaN(numericAmount)) {
            setFinalAmount("");
            setFormFee("");
            return;
        }
        if (numericAmount > 0) {
            try {
                const tempFee = numericAmount * (fees / 100);
                setFormFee(tempFee.toFixed(2)); // toFixed to standardize the fee format
                setFinalAmount((numericAmount - tempFee).toFixed(2)); // toFixed to standardize the final amount format
            } catch (error) {
                console.error('Error calculating fee or final amount:', error);
                setFinalAmount("");
                setFormFee("");
            }
        } else {
            setFinalAmount("");
            setFormFee("");
        }
    };

    const getWithdrawCondition = async () => {
        try {
            const response = await AxiosGet(ApiPaths.getWithdrawCondition); // Replace with actual API endpoint
            console.log(response, "getWithdrawCondition.......");
            setWithdrawFee(response?.data)

        } catch (error) {
            console.error("Error fetching Withdraw Condition:", error);
        }
    };

    const getWallets = async () => {
        try {
            const response = await AxiosGet(ApiPaths.getWallets);
            console.log(response, "getWallets.......");
            setWalletData(response)

        } catch (error) {
            console.error("Error fetching Wallet Data:", error);
        }
    };

    useEffect(() => {
        FetchData();
    }, [currentPage])

    const FetchData = async () => {
        try {
            setLoading(true);
            //   const filteredSearch = Object.fromEntries(
            //     Object.entries(search).filter(([key, value]) => value !== "")
            //   );
            const filters = {
                source: "withdrawal",
                // ...filteredSearch,
            };
            const queryParams = {
                page: currentPage,
                limit: 20,
                ...filters,
            };
            //   if (startDate && endDate) {
            //     queryParams.start_date = startDate;
            //     queryParams.end_date = endDate;
            //   }
            const response = await AxiosGet(
                `${ApiPaths.getPaymentTransaction}?${new URLSearchParams(
                    queryParams
                ).toString()}`
            );
            console.log(response, "Response")
            //   setTotalAmount(response?.debitSum);
            setPaymentTransaction(response?.data || []);
            setTotalPages(response?.totalPages || 1);
        } catch (error) {
            console.error("Error fetching payment transactions:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getWithdrawCondition()
        getWallets()
        FetchData()
    }, [])
    const fees = withdrawFee?.main_wallet?.tx_charge;  //in %

    const maxWithdraw = async () => {
        // setFormAmount(withdrawFee?.max_withdrawal)
        setFormAmount((walletData?.wallets?.find(wallet => wallet.slug === "main_wallet")?.value || 0).toFixed(2))
        const numericAmount = parseFloat(formAmount);
        if (numericAmount > 0) {
            try {
                const tempFee = numericAmount * (fees / 100);
                setFormFee(tempFee.toFixed(2)); // toFixed to standardize the fee format
                setFinalAmount((numericAmount - tempFee).toFixed(2)); // toFixed to standardize the final amount format
            } catch (error) {
                console.error('Error calculating fee or final amount:', error);
                setFinalAmount("");
                setFormFee("");
            }
        } else {
            setFinalAmount("");
            setFormFee("");
        }
    }

    const WithdrawFunc = async () => {
        console.log("working")
        if (!formAmount > 0) {
            toastFailed('Please enter valid amount')
            return
        }
        try {
            setLoading(true);
            const body = {
                amount: formAmount
            }
            console.log("Body==>", body)
            const tempWithdrawData = await AxiosPost(ApiPaths.withdraw, body);
            toastSuccess(tempWithdrawData?.message)
            console.log("Withdraw Api Response", tempWithdrawData)
        } catch (error) {
            BasicInfo.isDebug && console.error("Error fetching wallet details:", error);
            toastFailed(error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div id="loading" className="bg-gradient-to-b from-black to-black w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full flex items-center justify-center z-50" style={{ background: 'black', display: 'none' }}>
                <div className="fancy-spinner">
                    <div className="ring" />
                    <div className="ring" />
                    <div className="dot">
                        <img src={DefiCoin} width="150px" height="150px" className="h-full w-full object-contain" alt="Defi World Logo" />
                    </div>
                </div>
            </div>

            <div id="mobile" className="demo2 relative">
                <div id="mobileBodyContent">
                    <Header />
                    <style dangerouslySetInnerHTML={{ __html: "\n    .withdraw-form .nice-select .option {\n        color: #000;\n    }\n" }} />
                    <div className="inner-content px-4 2xl:px-3 mx-auto mt-5 pb-20 overflow-hidden">
                        <section className="py-0">
                            <div className="container mx-auto">
                                <div className="flex flex-wrap w-full">
                                    <div className="contentBox w-full">
                                        {/* Old Class : w-full md:w-1/2 xl:w-2/5 mx-auto */}
                                        <div className="grid grid-cols-1  gap-4 mt-5">
                                            <div className="col-span-1 lg:col-span-6">
                                                <form method="POST" onsubmit="return confirmDelete();" id="withdraw-process-form" action="https://defiworld.ai/withdraw-process" className="h-full relative max-w-xl w-full mx-auto">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute bottom-[-15%] md:bottom-[-13%] lg:bottom-[-51px] -left-[45px] h-[200px] filter-light" xmlnsXlink="http://www.w3.org/1999/xlink" id="Layer_2" data-name="Layer 2" viewBox="0 0 105.51 153.94">
                                                        <defs>
                                                            <style dangerouslySetInnerHTML={{ __html: "\n                                            .cls-1 {\n                                                fill: #006f76;\n                                            }\n\n                                            .cls-1,\n                                            .cls-2,\n                                            .cls-3 {\n                                                stroke-width: 0px;\n                                            }\n\n                                            .cls-2 {\n                                                fill: #01ffff;\n                                            }\n\n                                            .cls-3 {\n                                                fill: none;\n                                            }\n\n                                            .cls-4 {\n                                                clip-path: url(#clippath-1);\n                                            }\n\n                                            .cls-5 {\n                                                clip-path: url(#clippath);\n                                            }\n                                        " }} />
                                                            <clipPath id="clippath">
                                                                <path className="cls-3" d="m4.28,0l101.23,5.1-11.88,134.5S.47,155.28,0,153.85" />
                                                            </clipPath>
                                                            <clipPath id="clippath-1">
                                                                <polygon className="cls-3" points="23.76 -104.68 159.21 -104.68 79.21 127.71 23.76 125.81 23.76 -104.68" />
                                                            </clipPath>
                                                        </defs>
                                                        <g id="Layer_2-2" data-name="Layer 2">
                                                            <g className="cls-5">
                                                                <g className="cls-4">
                                                                    <polygon className="cls-2" points="61.8 121.36 34.88 121.36 34.88 113.78 66.99 113.78 61.8 121.36" />
                                                                    <polygon className="cls-2" points="27.3 74.65 27.3 121.36 34.88 121.36 34.88 69.45 27.3 74.65" />
                                                                    <polygon className="cls-2" points="31.09 32.72 31.09 78.84 34.88 78.84 34.88 30.12 31.09 32.72" />
                                                                    <path className="cls-1" d="m522.34,114.74H33.92V-86.84h488.42V114.74Zm-486.49-1.93h484.56V-84.91H35.85V112.81Z" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute top-[-15%] md:top-[-13%] lg:top-[-51px] -right-[45px] h-[200px] filter-light" style={{ transform: 'rotate(180deg)' }} xmlnsXlink="http://www.w3.org/1999/xlink" id="Layer_2" data-name="Layer 2" viewBox="0 0 105.51 153.94">
                                                        <defs>
                                                            <style dangerouslySetInnerHTML={{ __html: "\n                                            .cls-1 {\n                                                fill: #006f76;\n                                            }\n\n                                            .cls-1,\n                                            .cls-2,\n                                            .cls-3 {\n                                                stroke-width: 0px;\n                                            }\n\n                                            .cls-2 {\n                                                fill: #01ffff;\n                                            }\n\n                                            .cls-3 {\n                                                fill: none;\n                                            }\n\n                                            .cls-4 {\n                                                clip-path: url(#clippath-1);\n                                            }\n\n                                            .cls-5 {\n                                                clip-path: url(#clippath);\n                                            }\n                                        " }} />
                                                            <clipPath id="clippath">
                                                                <path className="cls-3" d="m4.28,0l101.23,5.1-11.88,134.5S.47,155.28,0,153.85" />
                                                            </clipPath>
                                                            <clipPath id="clippath-1">
                                                                <polygon className="cls-3" points="23.76 -104.68 159.21 -104.68 79.21 127.71 23.76 125.81 23.76 -104.68" />
                                                            </clipPath>
                                                        </defs>
                                                        <g id="Layer_2-2" data-name="Layer 2">
                                                            <g className="cls-5">
                                                                <g className="cls-4">
                                                                    <polygon className="cls-2" points="61.8 121.36 34.88 121.36 34.88 113.78 66.99 113.78 61.8 121.36" />
                                                                    <polygon className="cls-2" points="27.3 74.65 27.3 121.36 34.88 121.36 34.88 69.45 27.3 74.65" />
                                                                    <polygon className="cls-2" points="31.09 32.72 31.09 78.84 34.88 78.84 34.88 30.12 31.09 32.72" />
                                                                    <path className="cls-1" d="m522.34,114.74H33.92V-86.84h488.42V114.74Zm-486.49-1.93h484.56V-84.91H35.85V112.81Z" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <div className="overflow-x-auto max-h-[458px] overflow-y-auto p-3 md:p-6 rounded- border border-[#006f76] relative shadow-inner mb-5 h-full">
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <label htmlFor="amount" className="block text-sm text-white text-opacity-80 mb-2">Enter Amount</label>

                                                            <label htmlFor="amount" className="block text-sm text-white text-opacity-80 mb-2">Available Amount:$ {(walletData?.wallets?.find(wallet => wallet.slug === "main_wallet")?.value || 0).toFixed(2)}</label>
                                                        </div>
                                                        <div className="flex items-center mb-3 md:mb-5 border border-[#006f76] relative rounded-md">
                                                            <span className="inline-block px-3 py-2 border-r-0 border-gray-300 border-opacity-10">
                                                                <i className="las la-money-bill text-[#00ffff] text-xl" />
                                                            </span>
                                                            <input className="w-full bg-transparent	text-white pl-2 pr-6 py-2 border-0 focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none" autoComplete="off" id="amount" onChange={(e) => handleAmountChnage(e.target.value)} type="number" min={1} required name="amount" placeholder="Enter Amount" value={formAmount} />
                                                            <button className="bg-gradient-to-br from-primary/90 to-primary/60 rounded-md py-3 text-sm whitespace-nowrap px-3 hover:bg-secondary text-white transition duration-200" onClick={maxWithdraw} type="button">MAX</button>
                                                        </div>
                                                        <label htmlFor="amount" className="block text-sm text-white text-opacity-80 mb-2">Withdrawal Fees {fees}%</label>
                                                        <div className="flex items-center mb-3 md:mb-5 border border-[#006f76] relative rounded-md">
                                                            <span className="inline-block px-3 py-2 border-r-0 border-gray-300 border-opacity-10">
                                                                <i className="las la-money-bill text-[#00ffff] text-xl" />
                                                            </span>
                                                            <input className="w-full bg-transparent	text-white pl-2 pr-6 py-2 border-0 focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none" name="admin_charge" id="adminFees" placeholder="Withdrawal Fees " readOnly value={formFee} />
                                                        </div>
                                                        <label htmlFor="amount" className="block text-sm text-white text-opacity-80 mb-2">Your final Amount</label>
                                                        <div className="flex items-center mb-3 md:mb-5 border border-[#006f76] relative rounded-md">
                                                            <span className="inline-block px-3 py-2 border-r-0 border-gray-300 border-opacity-10">
                                                                <i className="las la-money-bill text-[#00ffff] text-xl" />
                                                            </span>
                                                            <input className="w-full bg-transparent	text-white pl-2 pr-6 py-2 border-0 focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none" id="afterAdminFees" placeholder="Your amount after Withdrawal fees" readOnly name="net_amount" value={finalAmount} />
                                                        </div>
                                                        <button className="bg-gradient-to-br from-primary/90 to-primary/60 rounded-md py-3 text-sm w-full hover:bg-secondary text-white transition duration-200" id="processButton" onClick={WithdrawFunc} type="button" name="btnSubmit">Withdraw</button>


                                                        {/* <Link to="https://defiworld.ai/voluntary-exit"><button className="bg-gradient-to-br from-primary/90 to-primary/60 rounded-md py-3 text-sm w-full hover:bg-secondary text-white transition duration-200 mt-3" type="button">Volunteer Exit</button></Link> */}
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-span-1 lg:col-span-6">
                                                <div className="flex flex-wrap justify-center gap-4 mt-5">
                                                    <div className="px-3 py-4 rounded-md relative mb-2 w-80 h-auto defiboxIn flex justify-between items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow">
                                                            <defs>
                                                                <style dangerouslySetInnerHTML={{ __html: "\n                                                    .cls-1 {\n                                                        opacity: .24;\n                                                    }\n\n                                                    .cls-1,\n                                                    .cls-2 {\n                                                        fill: #29abe2;\n                                                    }\n\n                                                    .cls-1,\n                                                    .cls-2,\n                                                    .cls-3 {\n                                                        stroke-width: 0px;\n                                                    }\n\n                                                    .cls-2 {\n                                                        opacity: .29;\n                                                    }\n\n                                                    .cls-3 {\n                                                        fill: aqua;\n                                                    }\n                                                " }} />
                                                            </defs>
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g>
                                                                    <g>
                                                                        <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                                                        <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                                                        <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                                                                    </g>
                                                                </g>
                                                                <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                                                                <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                                                                <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                                                                <g>
                                                                    <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                                                                    <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                                                                    <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                                                                    <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                                                                    <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                                                                    <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                                                                    <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                                                                    <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                                                                    <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                                                                    <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                                                                    <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                                                            </g>
                                                        </svg>
                                                        <div className="icon-box relative z-10 mb-8">
                                                            <img src={PendingWithdraw} className="w-auto h-12 max-h-12 max-w-12 logo-glow" width={48} height={48} alt="pending-withdraw" />
                                                        </div>
                                                        <div className="text-left relative z-10 w-3/4 ml-auto">
                                                            <h4 className="name text-sm  text-white text-opacity-80 text-center">Total Income</h4>
                                                            <div className="text-base text-white font-normal font-medium tracking-wider text-center">$ {(walletData?.totalIncome || 0).toFixed(2)}


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 py-4 rounded-md relative mb-2 w-80 h-auto defiboxIn flex justify-between items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow">
                                                            <defs>
                                                                <style dangerouslySetInnerHTML={{ __html: "\n                                                .cls-1 {\n                                                    opacity: .24;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2 {\n                                                    fill: #29abe2;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    opacity: .29;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: aqua;\n                                                }\n                                            " }} />
                                                            </defs>
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g>
                                                                    <g>
                                                                        <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                                                        <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                                                        <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                                                                    </g>
                                                                </g>
                                                                <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                                                                <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                                                                <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                                                                <g>
                                                                    <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                                                                    <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                                                                    <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                                                                    <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                                                                    <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                                                                    <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                                                                    <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                                                                    <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                                                                    <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                                                                    <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                                                                    <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                                                            </g>
                                                        </svg>
                                                        <div className="icon-box relative z-10 mb-8">
                                                            <img src={Availablbalance} className="w-auto h-12 max-h-12 max-w-12 logo-glow" width={48} height={48} alt="available-balance" />
                                                        </div>
                                                        <div className="text-left relative z-10 w-3/4 ml-auto">
                                                            <h4 className="name text-sm text-white text-opacity-80 pr-2 text-center">Available balance</h4>
                                                            <div className="text-base text-white font-normal font-medium tracking-wider text-center">$ {(walletData?.wallets?.find(wallet => wallet.slug === "main_wallet")?.value || 0).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 py-4 rounded-md relative mb-2 w-80 h-auto defiboxIn flex justify-between items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow">
                                                            <defs>
                                                                <style dangerouslySetInnerHTML={{ __html: "\n                                                .cls-1 {\n                                                    opacity: .24;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2 {\n                                                    fill: #29abe2;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    opacity: .29;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: aqua;\n                                                }\n                                            " }} />
                                                            </defs>
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g>
                                                                    <g>
                                                                        <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                                                        <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                                                        <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                                                                    </g>
                                                                </g>
                                                                <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                                                                <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                                                                <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                                                                <g>
                                                                    <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                                                                    <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                                                                    <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                                                                    <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                                                                    <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                                                                    <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                                                                    <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                                                                    <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                                                                    <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                                                                    <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                                                                    <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                                                            </g>
                                                        </svg>
                                                        <div className="icon-box relative z-10 mb-8">
                                                            <img src={DailyBouns} className="w-auto h-12 max-h-12 max-w-12 logo-glow" width={48} height={48} alt="daily-bonus" />
                                                        </div>
                                                        <div className="text-left relative z-10 w-3/4 ml-auto">
                                                            <h4 className="name text-sm text-white text-opacity-80 pr-2 text-center">Daily Bonus Income</h4>
                                                            <div className="text-base text-white font-normal font-medium tracking-wider text-center">$ {(walletData?.wallets?.find(wallet => wallet.slug === "roi_income")?.value || 0).toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 py-4 rounded-md relative mb-2 w-80 h-auto defiboxIn flex justify-between items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow">
                                                            <defs>
                                                                <style dangerouslySetInnerHTML={{ __html: "\n                                                .cls-1 {\n                                                    opacity: .24;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2 {\n                                                    fill: #29abe2;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    opacity: .29;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: aqua;\n                                                }\n                                            " }} />
                                                            </defs>
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g>
                                                                    <g>
                                                                        <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                                                        <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                                                        <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                                                                    </g>
                                                                </g>
                                                                <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                                                                <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                                                                <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                                                                <g>
                                                                    <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                                                                    <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                                                                    <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                                                                    <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                                                                    <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                                                                    <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                                                                    <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                                                                    <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                                                                    <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                                                                    <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                                                                    <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                                                            </g>
                                                        </svg>
                                                        <div className="icon-box relative z-10 mb-8">
                                                            <img src={LevelBonus} className="w-auto h-12 max-h-12 max-w-12 logo-glow" alt="" />
                                                        </div>
                                                        <div className="text-left relative z-10 w-3/4 ml-auto">
                                                            <h4 className="name text-sm text-white text-opacity-80 pr-2 text-center">Level Bonus Income</h4>
                                                            <div className="text-base text-white font-normal font-medium tracking-wider text-center">$ {(walletData?.wallets?.find(wallet => wallet.slug === "roi_level_income")?.value || 0).toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="px-3 py-4 rounded-md relative mb-2 w-80 h-auto defiboxIn flex justify-between items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow">
                                                            <defs>
                                                                <style dangerouslySetInnerHTML={{ __html: "\n                                                .cls-1 {\n                                                    opacity: .24;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2 {\n                                                    fill: #29abe2;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    opacity: .29;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: aqua;\n                                                }\n                                            " }} />
                                                            </defs>
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g>
                                                                    <g>
                                                                        <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                                                        <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                                                        <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                                                                    </g>
                                                                </g>
                                                                <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                                                                <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                                                                <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                                                                <g>
                                                                    <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                                                                    <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                                                                    <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                                                                    <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                                                                    <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                                                                    <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                                                                    <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                                                                    <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                                                                    <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                                                                    <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                                                                    <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                                                            </g>
                                                        </svg>
                                                        <div className="icon-box relative z-10 mb-8">
                                                            <img src={BoosterBonus} className="w-auto h-12 max-h-12 max-w-12 logo-glow" alt="" />
                                                        </div>
                                                        <div className="text-left relative z-10 w-3/4 ml-auto">
                                                            <h4 className="name text-sm text-white text-opacity-80 pr-2 text-center">Booster Bonus Income</h4>
                                                            <div className="text-base text-white font-normal font-medium tracking-wider text-center">0</div>
                                                        </div>
                                                    </div> */}
                                                    <div className="px-3 py-4 rounded-md relative mb-2 w-80 h-auto defiboxIn flex justify-between items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow">
                                                            <defs>
                                                                <style dangerouslySetInnerHTML={{ __html: "\n                                                .cls-1 {\n                                                    opacity: .24;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2 {\n                                                    fill: #29abe2;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    opacity: .29;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: aqua;\n                                                }\n                                            " }} />
                                                            </defs>
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g>
                                                                    <g>
                                                                        <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                                                        <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                                                        <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                                                                    </g>
                                                                </g>
                                                                <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                                                                <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                                                                <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                                                                <g>
                                                                    <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                                                                    <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                                                                    <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                                                                    <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                                                                    <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                                                                    <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                                                                    <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                                                                    <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                                                                    <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                                                                    <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                                                                    <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                                                            </g>
                                                        </svg>
                                                        <div className="icon-box relative z-10 mb-8">
                                                            <img src={RoyalityBonus} className="w-auto h-12 max-h-12 max-w-12 logo-glow" alt="" />
                                                        </div>
                                                        <div className="text-left relative z-10 w-3/4 ml-auto">
                                                            <h4 className="name text-sm text-white text-opacity-80 pr-2 text-center">Royalty Bonus</h4>
                                                            <div className="text-base text-white font-normal font-medium tracking-wider text-center">$ {(walletData?.wallets?.find(wallet => wallet.slug === "royality_income")?.value || 0).toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 py-4 rounded-md relative mb-2 w-80 h-auto defiboxIn flex justify-between items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow">
                                                            <defs>
                                                                <style dangerouslySetInnerHTML={{ __html: "\n                                                .cls-1 {\n                                                    opacity: .24;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2 {\n                                                    fill: #29abe2;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    opacity: .29;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: aqua;\n                                                }\n                                            " }} />
                                                            </defs>
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g>
                                                                    <g>
                                                                        <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                                                        <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                                                        <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                                                                    </g>
                                                                </g>
                                                                <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                                                                <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                                                                <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                                                                <g>
                                                                    <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                                                                    <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                                                                    <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                                                                    <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                                                                    <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                                                                    <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                                                                    <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                                                                    <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                                                                    <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                                                                    <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                                                                    <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                                                            </g>
                                                        </svg>
                                                        <div className="icon-box relative z-10 mb-8">
                                                            <img src={LevelBonus} className="w-auto h-12 max-h-12 max-w-12 logo-glow" alt="" />
                                                        </div>
                                                        <div className="text-left relative z-10 w-3/4 ml-auto">
                                                            <h4 className="name text-sm text-white text-opacity-80 pr-2 text-center">Reward Bonus</h4>
                                                            <div className="text-base text-white font-normal font-medium tracking-wider text-center">$ {(walletData?.wallets?.find(wallet => wallet.slug === "reward_income")?.value || 0).toFixed(2)}</div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="px-3 py-4 rounded-md relative mb-2 w-80 h-auto defiboxIn flex justify-between items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2" viewBox="0 0 698.32 236.61" className="absolute h-auto left-0 top-0 w-full cardglow">
                                                            <defs>
                                                                <style dangerouslySetInnerHTML={{ __html: "\n                                                .cls-1 {\n                                                    opacity: .24;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2 {\n                                                    fill: #29abe2;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    opacity: .29;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: aqua;\n                                                }\n                                            " }} />
                                                            </defs>
                                                            <g id="Layer_1-2" data-name="Layer 1">
                                                                <g>
                                                                    <g>
                                                                        <polygon className="cls-3" points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75" />
                                                                        <polygon className="cls-3" points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75" />
                                                                        <polygon className="cls-3" points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45" />
                                                                    </g>
                                                                </g>
                                                                <polygon className="cls-3" points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83" />
                                                                <path className="cls-3" d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z" />
                                                                <polygon className="cls-3" points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4" />
                                                                <g>
                                                                    <rect className="cls-3" x="616.26" y="29.95" width="10.82" height="9.23" transform="translate(656.23 -587.1) rotate(90)" />
                                                                    <rect className="cls-3" x="598.53" y="29.95" width="10.82" height="9.23" transform="translate(638.5 -569.37) rotate(90)" />
                                                                    <rect className="cls-3" x="580.8" y="29.95" width="10.82" height="9.23" transform="translate(620.78 -551.65) rotate(90)" />
                                                                    <rect className="cls-3" x="563.08" y="29.95" width="10.82" height="9.23" transform="translate(603.05 -533.92) rotate(90)" />
                                                                    <rect className="cls-3" x="545.35" y="29.95" width="10.82" height="9.23" transform="translate(585.32 -516.19) rotate(90)" />
                                                                    <rect className="cls-3" x="527.62" y="29.95" width="10.82" height="9.23" transform="translate(567.6 -498.47) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <rect className="cls-3" x="365.72" y="190.41" width="10.82" height="9.23" transform="translate(566.16 -176.09) rotate(90)" />
                                                                    <rect className="cls-3" x="347.99" y="190.41" width="10.82" height="9.23" transform="translate(548.43 -158.37) rotate(90)" />
                                                                    <rect className="cls-3" x="330.26" y="190.41" width="10.82" height="9.23" transform="translate(530.7 -140.64) rotate(90)" />
                                                                    <rect className="cls-3" x="312.54" y="190.41" width="10.82" height="9.23" transform="translate(512.98 -122.91) rotate(90)" />
                                                                    <rect className="cls-3" x="294.81" y="190.41" width="10.82" height="9.23" transform="translate(495.25 -105.19) rotate(90)" />
                                                                    <rect className="cls-3" x="277.08" y="190.41" width="10.82" height="9.23" transform="translate(477.52 -87.46) rotate(90)" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <g>
                                                                    <path className="cls-3" d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                    <path className="cls-3" d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z" />
                                                                </g>
                                                                <polygon className="cls-3" points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65" />
                                                            </g>
                                                        </svg>
                                                        <div className="icon-box relative z-10 mb-8">
                                                            <img src={TotalWithdraw} className="w-auto h-12 max-h-12 max-w-12 logo-glow" alt="" />
                                                        </div>
                                                        <div className="text-left relative z-10 w-3/4 ml-auto">
                                                            <h4 className="name text-sm text-white text-opacity-80 pr-2 text-center">Differential Bonus</h4>
                                                            <div className="text-base text-white font-normal font-medium tracking-wider text-center">0</div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* History */}
                                        {paymentTransaction?.length > 0 ? (
                                            <div className="overflow-x-auto max-h-[458px] overflow-y-auto p-3 md:p-6 rounded-md bg-stone-700 bg-opacity-10 border border-[#006f76] relative shadow-inner mt-10">
                                                <div className="inline-block min-w-full rounded-5xl overflow-hidden ">
                                                    <table className="table-auto w-full dataTable text-white">
                                                        <thead>
                                                            <tr className="text-body">
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Sr.</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Amount ($)</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Status</th>
                                                                {/* <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Type</th> */}
                                                                {/* <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left" id="xInfoIcon" title="your withdraw should be expected within - n * 15 seconds ( n = your queue no. )">Queue</th> */}
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Tx Charges</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Transaction ID</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {paymentTransaction.map((x, i) => (
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{parseFloat(x?.amount).toFixed(2)}</td>
                                                                    <td>
                                                                        {x?.status === 0 ? 'Pending' : x?.status === 1 ? 'Approved' : 'Rejected'}
                                                                    </td>

                                                                    {/* <td>{x?.tx_type}</td> */}
                                                                    <td>{x?.tx_charge}</td>
                                                                    <td>{x?.tx_Id}</td>
                                                                    <td>{moment(x?.time).format("MMM Do YY")}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Pagination
                                                    currentPage={currentPage}
                                                    totalPages={totalPages}
                                                    onPageChange={(page) => setCurrentPage(page)}
                                                />
                                            </div>
                                        ) : (
                                            // <div className="text-center text-white py-4">No payment transactions available</div>
                                            null
                                        )}

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    {/*  */}
                </div>
            </div>

            <Footer />
            {/*  */}
            {/* Tailwind CDN CSS */}
            {/*  */}
        </div>)
}
