import React, { useEffect, useState } from "react";
import DefiCoin from "./../../images/DeFi_Coin.webp";
import Sponsor from "./../../images/iconss/sponsor.webp";
import Calender from "./../../images/iconss/calendar.webp";
import Income from "./../../images/iconss/income.webp";
import Team from "./../../images/iconss/team.webp";
import Rank from "./../../images/iconss/rank.webp";
import RankIcon from "./../../images/icons/rank-icon.svg";
import DwcPrice from "./../../images/icons/dwc-price.webp";
import TeamBusiness from "./../../images/iconss/team_business.webp";
import DirectionIcon from "./../../images/iconss/direction-icon.svg";
import TotalWithdraw from "./../../images/icons/total-withdraw.webp";
import DateOfActivation from "./../../images/icons/date-of-activation.webp";
import TotalInvestment from "./../../images/icons/total-investment.webp";
import PendingWithdraw from "./../../images/icons/pending-withdraw.webp";
import FlushedIncome from "./../../images/icons/flushed-income.webp";
import DailyBonus from "./../../images/icons/daily-bonus.webp";
import LevelBonus from "./../../images/icons/level-bonus.webp";
import BoosterBonus from "./../../images/icons/booster-bonus.webp";
import RoyalityBounus from "./../../images/icons/royalty-bonus.webp";
import RewardBounus from "./../../images/icons/reward-bounus.webp";
import RoyaltyBonus from "./../../images/icons/royalty_Bonus.webp";
import "./DashBoard.css";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Slider from "../../Components/Slider";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import { useDispatch, useSelector } from "react-redux";
// import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import {
  setProfileData,
  setRanksData,
  setTeamsData,
  setWalletsData,
} from "../../Redux/DashboardDataSlice";
import ValueCardDiv from "../../Components/ValueCardDiv";
import Refer from "../../Components/Refer/Refer";
import ArrayToObject from "../../Common/ArrayToObject";
import moment from "moment";
import Change from "../../Common/StringToSub";
import axios from "axios";
import { setRate } from './../../Redux/rateSlice';
import { FiCopy } from "react-icons/fi";
import TokenLiveRate from "../../Common/TokenLiveRate";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);


export default function DashBoard() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { AxiosGet } = useAxiosHelper([]);
  const walletData = useSelector((state) => state?.dashboardData?.walletsData);
  const profileData = useSelector((state) => state?.dashboardData?.profileData);
  const teamData = useSelector((state) => state?.dashboardData?.teamsData);
  const rankData = useSelector((state) => state?.dashboardData?.ranksData);
  const price = useSelector((state) => state?.rate?.price);
  const rate = useSelector((state) => state.rate.price);

const activeRank = [...rankData].reverse().find(rank => rank.status === 1);


  var x = 0;
  useEffect(() => {
    if (x == 0) {
      FetchData();
      fetchRate();
      fetchOrders();console.log(rankData,"llllllllll")
      x++;
    }
  }, []);

  const fetchOrders = async (filters = {}) => {
    try {
      // Default filters with status=1
      const queryParams = { status: 1, ...filters };

      // Convert the filters object to a query string
      const queryString = new URLSearchParams(queryParams).toString();

      const response = await AxiosGet(`${ApiPaths.getOrders}?${queryString}`);
      setOrders(response?.data || []);
      // BasicInfo.isDebug && console.log(response?.data, "response of orders");

      if (response) {
        toastSuccess(response?.message);
        // setTotalPages(response?.totalPages || 1);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };


  const FetchData = async () => {
    try {
      const [tempWallets, tempTeams, tempProfile, tempRanks] =
        await Promise.all([
          AxiosGet(ApiPaths.getWallets),
          AxiosGet(ApiPaths.getTeamSection),
          AxiosGet(ApiPaths.getProfile),
          AxiosGet(ApiPaths.getRanks),
        ]);
      const objectWalletData = ArrayToObject(tempWallets?.wallets);
      // BasicInfo.isDebug && console.log("objectWalletData", objectWalletData);
      dispatch(setWalletsData(objectWalletData));
      dispatch(setTeamsData(tempTeams));
      dispatch(setProfileData(tempProfile));
      dispatch(setRanksData(tempRanks));
    } catch (e) {
      console.error("Error fetching data", e);
    }
  };
  const fetchRate = async () => {
    try {
      const tempPrice = await TokenLiveRate();
      dispatch(setRate(tempPrice))
    } catch (error) {
      console.error('Error fetching the rate:', error);
    }
  };


  const [activeTab, setActiveTab] = useState('');
  const [orders, setOrders] = useState([]);
  const latestOrders = orders.reverse().slice(0, 4);
  const [bonusData, setBonusData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [roiIncome, setRoiIncome] = useState(0);
  const [copyTrader, setCopyTrader] = useState(0);
  useEffect(() => {
    // Fetch data from the backend API
    const fetchData = async () => {
      try {
        const response = await AxiosGet(ApiPaths.getWallets);
        const wallets = response?.wallets;

        // Process the data to extract totalInvestment and totalIncome
        let investment = 0;
        let income = 0;
        let roiIncome = 0;

        wallets.forEach(item => {
          if (item.wallet_type === 'investment' && item.name === 'Self Investment') {
            investment = item.value;
          } else if (item.wallet_type === 'income') {
            income += item.value;
          } else if (item.wallet_type === 'roi') {
            roiIncome += item.value;
          }
        });

        setTotalInvestment(investment);
        setTotalIncome(income);
        setRoiIncome(roiIncome);

        // Calculate values for Bonus
        const bonusIncome = investment * 3;
        const bonusIncomeLeft = bonusIncome - income;
        // BasicInfo.isDebug && console.log(bonusIncome, "bonusIncome")
        // BasicInfo.isDebug && console.log(bonusIncomeLeft, "bonusIncomeLeft")
        setBonusData([
          { name: 'Bonus Income', value: bonusIncome },
          { name: 'Income Left', value: bonusIncomeLeft },
        ]);

        // Calculate values for Earning
        const earningIncome = investment * 2;
        const earningIncomeLeft = earningIncome - roiIncome;
        // BasicInfo.isDebug && console.log(earningIncome, "earningIncome")
        // BasicInfo.isDebug && console.log(earningIncomeLeft, "earningIncomeLeft")

        setChartData([
          { name: 'Earning Income', value: earningIncome },
          { name: 'ROI Income Left', value: earningIncomeLeft },
        ]);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const TopEarners = async () => {
    try {
      const topEarners = await AxiosGet(ApiPaths.getTraders);
      // console.log(topEarners, "<====Top Earners");
      setCopyTrader(topEarners?.data);
    } catch (e) {
      console.log("Error in fetching Top Earners", e);
    }
  };

  useEffect(() => {
    TopEarners()
  }, [])


  const COLORS = ['#0072d7', '#a3e5ff'];

  return (
    <>
      <div
        id="loading"
        className="bg-gradient-to-b from-black to-black w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full flex items-center justify-center z-50"
        style={{ background: "black", display: "none" }}
      >
        <div className="fancy-spinner">
          <div className="ring" />
          <div className="ring" />
          <div className="dot">
            <img
              src={DefiCoin}
              width="150px"
              height="150px"
              className="h-full w-full object-contain"
              alt="Defi World Logo"
            />
          </div>
        </div>
      </div>

      <div id="mobile" className="demo2 relative">
        <div id="mobileBodyContent">
          <Header />
          <div className="inner-content px-4 2xl:px-3  mx-auto mt-5 pb-20">
            <section className="pb-0">
              <div className="container overflow-hidden mx-auto">
                <div className="flex flex-wrap">
                  <div className="contentBox">
                    {/* <Slider /> */}
                    <Refer />
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 md:gap-6 mb-4 md:mb-6">
                      <div className="col-span-1">
                        <div className="flex flex-wrap justify-center gap-8 mb-6">
                          <ValueCardDiv
                            logoImage={Sponsor}
                            title="Sponsor"
                            // value="8e5DEd"
                            value={"#" + Change(profileData.sponsor_username)}
                          />
                          <ValueCardDiv
                            logoImage={Calender}
                            title="Date of Joining"
                            value={
                              profileData?.joining_date
                                ? moment(profileData.joining_date).format(
                                  "MMM Do YY"
                                )
                                : "N/A"
                            }
                          />
                          <ValueCardDiv
                            logoImage={Income}
                            title="Total Income"
                            value={`$${walletData?.total_payout?.value}`}
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="flex flex-wrap justify-center gap-8 mb-6">
                          <ValueCardDiv
                            logoImage={Team}
                            title="Total Team"
                            value={teamData?.working?.sum?.total_team}
                          />
                          {/* <ValueCardDiv
                            logoImage={Team}
                            title="Referral Link"
                            value={
                              <div className="flex items-center">
                                {Change(`${window.location.origin}/register?ref=${profileData?.wallet_address}`)}
                                <FiCopy
                                  className="ml-2 cursor-pointer"
                                  onClick={() =>
                                    copyToClipboard(`${window.location.origin}/register?ref=${profileData?.wallet_address}`)
                                  }
                                />
                              </div>
                            }
                          /> */}
                          <ValueCardDiv
                            logoImage={Team}
                            title="Active Teams"
                            value={teamData?.working?.sum?.active_team}
                          />
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="flex flex-wrap justify-center gap-8 mb-6">
                          <ValueCardDiv
                            logoImage={Rank}
                            title="Rank"
                            value={activeRank ? activeRank.rankname : "No active rank available"}
                          />
                          <ValueCardDiv
                            logoImage={TeamBusiness}
                            title="Team Business"
                            value={teamData?.working?.sum?.business}
                          />
                          <ValueCardDiv
                            logoImage={TeamBusiness}
                            title="Direct Business"
                            value={teamData?.working?.teamSection?.[0]?.business}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-10 2xl:gap-6 mb-4 md:mb-6">
                      <div className="col-span-1 mb-4">
                        <div className="w-full sm:h-full teamPerformanceBox mb relative">
                          <div className="flex flex-wrap justify-center sm:justify-between items-center z-50">
                            <h2 className="text-lg font-semibold text-[#f0f0f0] tracking-wider mb-4 textdefibold sm:pl-5">
                              Team Performance
                            </h2>
                            <ul
                              className="roi-tab text-sm font-medium text-left space-x-2 flex flex-wrap justify-between mb-2"
                              role="tablist"
                            >
                              <li role="presentation">
                                <button
                                  className={`relative btnMU px-2 py-3 text-xl ${activeTab === 'profile' ? 'text-blue-600 border-blue-600' : 'text-gray-500 border-gray-100'
                                    }`}
                                  onClick={() => setActiveTab('TopEarners')}
                                  role="tab"
                                >
                                  Top Earners
                                </button>
                              </li>
                              <li role="presentation">
                                <button
                                  className={`relative btnMU px-2 py-3 text-xl ${activeTab === 'dashboard' ? 'text-blue-600 border-blue-600' : 'text-gray-500 border-gray-100'
                                    }`}
                                  onClick={() => setActiveTab('TopRecruiters')}
                                  role="tab"
                                >
                                  Top Recruiters
                                </button>
                              </li>
                              <li role="presentation">
                                <button
                                  className={`relative btnMU px-2 py-3 text-xl ${activeTab === 'contacts' ? 'text-blue-600 border-blue-600' : 'text-gray-500 border-gray-100'
                                    }`}
                                  onClick={() => setActiveTab('PackageHistory')}
                                  role="tab"
                                >
                                  Package History
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div id="myTabContent">

                            {activeTab === 'TopEarners' &&
                              <div style={{ overflowX: "auto" }}>
                                <table className="min-w-full divide-y divide-gray-200 mt-5">
                                  <thead>
                                    <tr>
                                      <th className="py-2 px-4 border-b text-left border-gray-300 whitespace-nowrap">Username</th>
                                      <th className="py-2 px-4 border-b text-center border-gray-300 whitespace-nowrap">Rank</th>
                                      <th className="py-2 px-4 border-b text-right border-gray-300 whitespace-nowrap">Wallet Address</th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200">
                                    {copyTrader.length > 0 ? (
                                      copyTrader.map((earner) => (
                                        <tr key={earner._id}>
                                          <td className="py-2 px-4 border-b text-left border-gray-300 whitespace-nowrap">{earner.username}</td>
                                          <td className="py-2 px-4 border-b text-center border-gray-300 whitespace-nowrap">{earner.current_rank ? earner.current_rank : "-"}</td>
                                          <td className="py-2 px-4 border-b text-right border-gray-300 whitespace-nowrap">{Change(earner.wallet_address)}</td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="3" className="px-6 py-4 text-sm text-gray-500 text-center">
                                          No Data found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            }

                            {activeTab === 'TopRecruiters' && <div id="dashboard" style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '20px',
                              color: '#fff'
                            }}><p>Top Recruiters UPCOMING....</p></div>}

                            {activeTab === 'PackageHistory' && <div id="contacts">
                              <div style={{ overflowX: "auto" }}>
                                {latestOrders.length > 0 ? (
                                  <table className="min-w-full divide-y divide-gray-200 mt-5">
                                    <thead>
                                      <tr>
                                        <th className="py-2 px-4 border-b text-left border-gray-300 whitespace-nowrap">Date</th>
                                        <th className="py-2 px-4 border-b text-center border-gray-300 whitespace-nowrap">Package ID</th>
                                        <th className="py-2 px-4 border-b text-right border-gray-300 whitespace-nowrap">Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                      {latestOrders.map((order) => (
                                        <tr key={order.id}>
                                          <td className="py-2 px-4 border-b text-left border-gray-300 whitespace-nowrap">
                                            {moment(order.createdAt).format("MMM Do YY")}
                                          </td>
                                          <td className="py-2 px-4 border-b text-center border-gray-300 whitespace-nowrap">
                                            {order.planId}
                                          </td>
                                          <td className="py-2 px-4 border-b text-right border-gray-300 whitespace-nowrap">
                                            {order.amount}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div className="px-6 py-4 text-sm text-white text-center">
                                    No orders found
                                  </div>
                                )}
                              </div>
                            </div>}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 mb-4">
                        <div className="w-full h-full  teamPerformanceBox">
                          <div className="flex justify-between items-center w-full px-0 p-3 relative border-b border-gray-100 border-opacity-20 mb-4 px-2">
                            <div className="text-left">
                              <h4 className="name text-md text-white textdefibold">
                                Total Directs
                              </h4>
                              <div className="text-sm lg:text-base text-white font-medium tracking-wider">
                                {teamData?.working?.teamSection?.[0]?.total_team || 0}
                              </div>
                            </div>

                            <div className="icon-box">
                              <img
                                src={DirectionIcon}
                                width={32}
                                height={32}
                                className="w-auto h-8 max-h-8 max-w-8"
                                alt=""
                              />
                            </div>
                            <div className="text-right">
                              <h4 className="name text-md text-white textdefibold">
                                Active Directs
                              </h4>
                              <div className="text-sm lg:text-base text-white font-medium tracking-wider">
                                {teamData?.working?.teamSection?.[0]?.active_team || 0}
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-between items-center w-full px-0 p-3 relative border-b border-gray-100 border-opacity-20 mb-4 xl:hidden">
                            <div className="text-left">
                              <h4 className="name text-md text-white textdefibold">
                                Rank
                              </h4>
                              <div className="text-sm lg:text-base text-white font-medium tracking-wider">
                                {activeRank ? activeRank.rankname : "No active rank available"}                              </div>
                            </div>
                            <div className="icon-box">
                              <img
                                src={RankIcon}
                                width={32}
                                height={32}
                                className="w-auto h-8 max-h-8 max-w-8"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 md:gap-6 mb-4 md:mb-6">
                      <div className="col-span-1 mb-4">
                        {orders && orders.length > 0 ? (
                          <div className="w-full h-full">
                            <h2 className="text-lg font-semibold text-[#f0f0f0] tracking-wider mb-6 text-center sm:text-left textdefibold">
                              Package Details
                            </h2>
                            <div className="overflow-x-auto no-scrollbar">
                              <div className="detailCardDiv flex  w-max sm:w-auto">
                                {orders.map((order, index) => {
                                  // Define the data for the pie chart based on max_return and totalIncome
                                  const data = {
                                    labels: ['Max Return', 'Total Income'],
                                    datasets: [
                                      {
                                        data: [
                                          order.pending_income || 0,
                                          (order.max_return || 0) - (order.pending_income || 0),
                                        ],
                                        backgroundColor: ['#00FFFF', '#ff7f50'],
                                        hoverBackgroundColor: ['#50dbff', '#e67e22'],
                                      },
                                    ],
                                  };

                                  return (
                                    <div
                                      key={index}
                                      className=" detailCard flex-shrink-0 mb-4 md:mb-0 border border-[#50dbff] opadown relative shadow-inner h-full p-3 md:p-6 rounded-sm border border-[#454545]"
                                    >
                                      <div className="text-center py-5 text-white">
                                        <div className="package-details text-white text-base flex flex-col gap-2 sm:gap-4">
                                          <div className="flex justify-between">
                                            <p><strong>Package No.:</strong></p>
                                            <p>{index + 1}</p>
                                          </div>
                                          <div className="flex justify-between">
                                            <p className="text-left"><strong>Package Name:</strong></p>
                                            <p className="text-right w-[150px]">{order.package_type || "-"}</p>
                                          </div>
                                          <div className="flex justify-between">
                                            <p><strong>Date:</strong></p>
                                            <p>{order.added_on ? moment(order.added_on).format("MMM Do YY") : "N/A"}</p>
                                          </div>
                                          <div className="flex justify-between">
                                            <p><strong>Package Amount:</strong></p>
                                            <p>{order.amount ? `${order.amount} $` : "0"}</p>
                                          </div>
                                          <div className="flex justify-between">
                                            <p><strong>Return:</strong></p>
                                            <p>{order.capping ? `${order.capping} %` : "-"}</p>
                                          </div>

                                          {/* Insert the Pie Chart here */}
                                          <div className="my-4">
                                            <Pie data={data} options={{ responsive: true, maintainAspectRatio: false }} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        ) : null}

                      </div>
                    </div>
                    {/* ============== */}
                    <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-6">
                      <div className="col-span-1">
                        <h2 className="text-lg font-semibold text-[#F0F0F0] tracking-wider mb-6 text-center textdefibold">
                          Analytics
                        </h2>
                        <div className="flex flex-wrap justify-center gap-8 mb-4">
                          <ValueCardDiv
                            logoImage={DwcPrice}
                            title="DWC Price"
                            value={rate !== null ? `$ ${rate}` : 'Loading...'}
                          />
                          <ValueCardDiv
                            logoImage={DateOfActivation}
                            title="Date of Activation"
                            value={
                              profileData?.Activation_date
                                ? moment(profileData.Activation_date).format(
                                  "MMM Do YY"
                                )
                                : "Not Activated Yet"
                            }
                          />
                          <ValueCardDiv
                            logoImage={TotalInvestment}
                            title="Total Investment"
                            value= {`$${(walletData?.self_investment?.value) + (walletData?.self_investment_non_working?.value) }`}
                          />
                          {/* <ValueCardDiv
                            logoImage={TotalWithdraw}
                            title="Total Withdraw"
                            value={`$${walletData?.total_withdrawal?.value}`}
                          />
                          <ValueCardDiv
                            logoImage={PendingWithdraw}
                            title="Pending Withdraw"
                            value={`$${walletData?.total_withdrawal?.value -
                              walletData?.total_payout?.value
                              }`}
                          />
                          <ValueCardDiv
                            logoImage={FlushedIncome}
                            title=" Flushed Income"
                            value="$0.00000"
                          /> */}
                        </div>
                      </div>
                      <div className="col-span-1">
                        <h2 className="text-lg font-semibold text-[#F0F0F0] tracking-wider mb-6 text-center textdefibold">
                          Earnings
                        </h2>
                        <div className="flex flex-wrap justify-center gap-8 mb-4">
                          <ValueCardDiv
                            logoImage={DailyBonus}
                            title="Monthly Reward"
                            value={`$${(walletData?.roi_income?.value ?? 0).toFixed(4)}`}

                          />
                          <ValueCardDiv
                            logoImage={LevelBonus}
                            title=" Level Bonus"
                            value={`$${walletData?.roi_level_income?.value}`}
                          />
                          <ValueCardDiv
                            logoImage={BoosterBonus}
                            title="Referral Income"
                            value={`$${walletData?.direct_income?.value || 0}`}
                          />
                          <ValueCardDiv
                            logoImage={Sponsor}
                            title="Working Business "
                            value={`$${teamData?.working?.sum?.business || 0}`}
                          />
                          <ValueCardDiv
                            logoImage={Sponsor}
                            title="Non-Working Business "
                            value={`$${teamData?.non_working?.sum?.business || 0}`}
                          />
                          {/* <ValueCardDiv
                              logoImage={BoosterBonus}
                              title="Booster Bonus"
                              value={`$${walletData?.booster_income?.value}`}
                            /> 
                           <ValueCardDiv
                            logoImage={RoyaltyBonus}
                            title="Differential Bonus"
                            value={`$${walletData?.differential_income?.value}`}
                          /> 
                           <ValueCardDiv
                            logoImage={RoyalityBounus}
                            title="Royalty Bonus"
                            value={`$${walletData?.royality_income?.value}`}
                          /> */}
                          <div className="px-3 py-4 rounded-md w-80 h-auto bg-opacity-10 relative mb-2 defiboxIn flex justify-between items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_2"
                              data-name="Layer 2"
                              viewBox="0 0 698.32 236.61"
                              className="absolute h-auto left-0 top-0 w-full cardglow"
                            >
                              <defs>
                                <style
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      "\n                                                .cls-1 {\n                                                    opacity: .24;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2 {\n                                                    fill: #29abe2;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    opacity: .29;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: aqua;\n                                                }\n                                            ",
                                  }}
                                />
                              </defs>
                              <g id="Layer_1-2" data-name="Layer 1">
                                <g>
                                  <g>
                                    <polygon
                                      className="cls-3"
                                      points="154.83 38.75 154.19 38.75 154.19 13.01 124.6 13.01 124.6 12.37 154.83 12.37 154.83 38.75"
                                    />
                                    <polygon
                                      className="cls-3"
                                      points="1.45 38.75 .81 38.75 .81 12.37 31.04 12.37 31.04 13.01 1.45 13.01 1.45 38.75"
                                    />
                                    <polygon
                                      className="cls-3"
                                      points="30.78 166.45 .54 166.45 .54 140.08 1.18 140.08 1.18 165.81 30.78 165.81 30.78 166.45"
                                    />
                                  </g>
                                </g>
                                <polygon
                                  className="cls-3"
                                  points="156.75 163.83 125.68 163.83 125.68 163.17 156.09 163.17 156.09 136.72 156.75 136.72 156.75 163.83"
                                />
                                <path
                                  className="cls-3"
                                  d="m546.37,232.56H0v-61.46h161.04V12.48h464.93l59.52,69.72v95.54h-92.34l-46.79,54.81ZM2,230.22h543.54l46.79-54.81h91.17v-92.23l-58.35-68.35H163.04v158.62H2v56.78Z"
                                />
                                <polygon
                                  className="cls-3"
                                  points="254.32 224.4 142.29 224.4 130.45 210.52 .2 210.52 .2 209.94 130.65 209.94 142.5 223.82 254.11 223.82 278.94 194.74 553.59 194.74 553.59 195.32 279.14 195.32 254.32 224.4"
                                />
                                <g>
                                  <rect
                                    className="cls-3"
                                    x="616.26"
                                    y="29.95"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(656.23 -587.1) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="598.53"
                                    y="29.95"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(638.5 -569.37) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="580.8"
                                    y="29.95"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(620.78 -551.65) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="563.08"
                                    y="29.95"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(603.05 -533.92) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="545.35"
                                    y="29.95"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(585.32 -516.19) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="527.62"
                                    y="29.95"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(567.6 -498.47) rotate(90)"
                                  />
                                </g>
                                <g>
                                  <rect
                                    className="cls-3"
                                    x="365.72"
                                    y="190.41"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(566.16 -176.09) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="347.99"
                                    y="190.41"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(548.43 -158.37) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="330.26"
                                    y="190.41"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(530.7 -140.64) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="312.54"
                                    y="190.41"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(512.98 -122.91) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="294.81"
                                    y="190.41"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(495.25 -105.19) rotate(90)"
                                  />
                                  <rect
                                    className="cls-3"
                                    x="277.08"
                                    y="190.41"
                                    width="10.82"
                                    height="9.23"
                                    transform="translate(477.52 -87.46) rotate(90)"
                                  />
                                </g>
                                <g>
                                  <path
                                    className="cls-3"
                                    d="m542.75,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                  <path
                                    className="cls-3"
                                    d="m527.91,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                  <path
                                    className="cls-3"
                                    d="m513.06,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                  <path
                                    className="cls-3"
                                    d="m498.21,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                  <path
                                    className="cls-3"
                                    d="m483.37,191.93h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                </g>
                                <g>
                                  <path
                                    className="cls-3"
                                    d="m237.22,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                  <path
                                    className="cls-3"
                                    d="m222.37,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                  <path
                                    className="cls-3"
                                    d="m207.53,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                  <path
                                    className="cls-3"
                                    d="m192.68,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                  <path
                                    className="cls-3"
                                    d="m177.83,8.17h-9.56l6.97-8.17h9.56l-6.97,8.17Zm-8.35-.59h8.14l5.97-7h-8.14l-5.97,7Z"
                                  />
                                </g>
                                <polygon
                                  className="cls-3"
                                  points="175.91 13.65 175.91 42.97 422.81 42.97 448.93 12.37 175.91 13.65"
                                />
                              </g>
                            </svg>
                            <div className="icon-box mb-8 relative z-10">
                              <img
                                src={RewardBounus}
                                width={65}
                                height={65}
                                className="w-auto h-12 max-h-12 max-w-12 logo-glow"
                                alt="r-bounus"
                              />
                            </div>
                            <div className="text-left relative z-10 w-3/4 ml-auto">
                              <h4 className="name text-sm text-white text-opacity-80 mb-1 pr-2 text-center">
                                Reward Bonus
                              </h4>
                              <div className="mdt text-base text-white font-medium tracking-wider text-center">
                                {`$${walletData?.reward_income?.value}`}
                              </div>
                            </div>
                            {/* <div className="text-left relative z-10 w-3/4 ml-auto">
                              <h4 className="name text-sm text-white text-opacity-80 mb-1 pr-2 text-center">
                                Working Income
                              </h4>
                              <div className="mdt text-base text-white font-medium tracking-wider text-center">
                                {`$${walletData?.reward_income?.value}`}
                              </div>
                            </div>
                            <div className="text-left relative z-10 w-3/4 ml-auto">
                              <h4 className="name text-sm text-white text-opacity-80 mb-1 pr-2 text-center">
                              Non-Working Income
                              </h4>
                              <div className="mdt text-base text-white font-medium tracking-wider text-center">
                                {`$${walletData?.reward_income?.value}`}
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <Footer />

      <grammarly-desktop-integration data-grammarly-shadow-root="true" />
    </>
  );
}
