import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import useAxiosHelper from '../../Common/AxiosHalper';
import { ApiPaths } from '../../Config/ApiPaths';
import Change from '../../Common/StringToSub';
import moment from 'moment';
import Select from 'react-select';
import Loader from '../../Components/Loader/Loader';
import { BasicInfo, toastFailed, toastSuccess } from '../../Config/BasicInfo';
import Pagination from '../../Components/PaginationControls/PaginationControls';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function MyTeam() {
    const [search, setSearch] = useState({
        search: '',
        name: '',
        username: '',
        current_rank: '',
        address: '',
        sponsor: '',
        amount: '',
        current_bonanza: '',
        startDateJoining: '',
        endDateJoining: '',
        status: '',
    });
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [teamData, setTeamData] = useState([]);
    const { AxiosPost, AxiosGet } = useAxiosHelper([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [levels, setLevels] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState(1);
    const [filterVisible, setFilterVisible] = useState(false);

    useEffect(() => {
        FetchData(selectedLevel);
    }, [selectedLevel, currentPage]);
    useEffect(() => {
        getLevels();
    }, []);

    const getLevels = async () => {
        try {
            const response = await AxiosGet(ApiPaths.getPackages);
            const levels = response?.packages?.[0]?.level_income?.level || [];
            const generatedLevels = [
                ...levels.map((_, index) => ({
                    name: `Level ${index + 1}`,
                    type: index + 1,
                })),
            ];
            setLevels(generatedLevels);
        } catch (error) {
            console.error('Error fetching levels:', error);
        }
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setSearch((prevSearch) => ({
            ...prevSearch,
            [name]: value,
        }));
    };

    const handleDateChange = (date, type) => {
        setSearch((prevSearch) => ({
            ...prevSearch,
            [type]: date,
        }));
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedLevel(selectedOption.value);
    };
    const limit = 10;
    const FetchData = async (selectedLevel) => {
        try {
            setLoading(true);
            const filteredSearch = Object.fromEntries(
                Object.entries(search).filter(([key, value]) => value !== '')
            );
            const queryParams = new URLSearchParams({
                page: currentPage,
                // levels: selectedLevel,
                limit: limit,
                ...filteredSearch,
            });

            const queryString = queryParams.toString();
            const res = await AxiosGet(`${ApiPaths.getAllTeam}?${queryString}`);

            setTeamData(res);
            if (res) {
                toastSuccess(res?.message);
                setTotalPages(res?.totalPages || 1);
            }
        } catch (e) {
            toastFailed(e?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    // Reset Filters Function
    // const resetFilters = () => {
    //     setSearch({
    //         search: '',
    //         name: '',
    //         username: '',
    //         startDateJoining: '',
    //         endDateJoining: '',
    //         status: '',
    //     });
    //     setCurrentPage(1);
    //     FetchData(selectedLevel);
    // };


    const [isResetting, setIsResetting] = useState(false);

    const resetFilters = () => {

        setSearch({
            search: '',
            name: '',
            username: '',
            current_rank: '',
            address: '',
            sponsor: '',
            amount: '',
            current_bonanza: '',
            startDateJoining: '',
            endDateJoining: '',
            status: '',
        });
        setCurrentPage(1);
        setIsResetting(true);
    };

    // useEffect to trigger FetchData when isResetting changes
    useEffect(() => {
        if (isResetting) {
            FetchData(selectedLevel);
            setIsResetting(false); // Reset the flag
        }
    }, [isResetting]);


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#000',
            borderColor: '#00ffff',
            color: '#00ffff',
            boxShadow: state.isFocused ? '0 0 5px #00ffff' : 'none',
            '&:hover': {
                borderColor: '#00ffff',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#000',
            color: '#00ffff',
            borderRadius: '4px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#00ffff' : state.isFocused ? '#333' : '#000',
            color: state.isSelected ? '#000' : '#00ffff',
            '&:hover': {
                backgroundColor: '#333',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#00ffff',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#00ffff',
            '&:hover': {
                color: '#00ffff',
            },
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: '#00ffff',
        }),
    };
    const toggleFilterVisibility = () => {
        setFilterVisible(!filterVisible);
    };

    const options = levels.map((x) => ({
        value: x.type,
        label: x.name,
    }));

    return (
        <div>
            {loading && <Loader />}
            <div id="mobile" className="demo2 relative">
                <div id="mobileBodyContent">
                    <Header />
                    <div className="inner-content px-4 2xl:px-2 mx-auto mt-5 pb-20 overflow-hidden">
                        <section className="font-normal bg-darkBlueGray-500 text-white mt-0">
                            <div className="container mx-auto">
                                <div className="flex flex-wrap w-full justify-center">
                                    <div className="contentBox w-full relative">
                                        <div className="mb-8 p-4 md:p-6 rounded-md bg-stone-700 bg-opacity-10 border border-[#00ffff] relative shadow-inner">
                                            <div className="flex justify-between">
                                                <div className="title text-lg font-medium text-white mb-3 tracking-wider textdefibold">My Team</div>
                                                {/* <Select
                                                    value={options.find((option) => option.value === selectedLevel)}
                                                    onChange={handleSelectChange}
                                                    options={options}
                                                    styles={customStyles}
                                                /> */}
                                            </div>
                                            <div>
                                                <button
                                                    onClick={toggleFilterVisibility}
                                                    className=" bg-[var(--colorPrimary)] rounded-md text-white px-4 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none hasDatepicker"
                                                >
                                                    {filterVisible ? 'Hide Filters' : 'Show Filters'}
                                                </button>
                                                {/* Filters Section */}
                                                {filterVisible && (
                                                    <div className="flex flex-wrap m-4 gap-2">
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Member Code"
                                                            value={search.search}
                                                            onChange={handleSearchChange}
                                                            className=" bg-[#000000] rounded-md text-white pl-6 pr-3 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none "
                                                        />
                                                        <input
                                                            type="text"
                                                            name="sponsor"
                                                            placeholder="Sponsor"
                                                            value={search.sponsor}
                                                            onChange={handleSearchChange}
                                                            className=" bg-[#000000] rounded-md text-white pl-6 pr-3 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none"
                                                        />
                                                        {/* <input
                                                            type="text"
                                                            name="amount"
                                                            placeholder="Amount"
                                                            value={search.amount}
                                                            onChange={handleSearchChange}
                                                            className=" bg-[#000000] rounded-md text-white pl-6 pr-3 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none "
                                                        /> */}
                                                        <input
                                                            type="text"
                                                            name="current_rank"
                                                            placeholder="Rank Name"
                                                            value={search.current_rank}
                                                            onChange={handleSearchChange}
                                                            className=" bg-[#000000] rounded-md text-white pl-6 pr-3 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none "
                                                        />
                                                        <input
                                                            type="text"
                                                            name="address"
                                                            placeholder="Wallet Address"
                                                            value={search.address}
                                                            onChange={handleSearchChange}
                                                            className=" bg-[#000000] rounded-md text-white pl-6 pr-3 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none "
                                                        />
                                                        <input
                                                            type="text"
                                                            name="current_bonanza"
                                                            placeholder="Bonanza"
                                                            value={search.current_bonanza}
                                                            onChange={handleSearchChange}
                                                            className=" bg-[#000000] rounded-md text-white pl-6 pr-3 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none "
                                                        />
                                                        <DatePicker
                                                            selected={search.startDateJoining}
                                                            onChange={(date) => handleDateChange(date, 'startDateJoining')}
                                                            placeholderText="From Date Joining"
                                                            className=" bg-[#000000] rounded-md text-white pl-6 pr-3 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none hasDatepicker"
                                                        />
                                                        <DatePicker
                                                            selected={search.endDateJoining}
                                                            onChange={(date) => handleDateChange(date, 'endDateJoining')}
                                                            placeholderText="To Date Joining"
                                                            className=" bg-[#000000] rounded-md text-white pl-6 pr-3 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none hasDatepicker"
                                                        />
                                                        <Select
                                                            value={{ value: search.status, label: search.status === '1' ? 'Active' : search.status === '0' ? 'Inactive' : 'All   ' }}
                                                            onChange={(option) => handleSearchChange({ target: { name: 'status', value: option.value } })}
                                                            options={[
                                                                { value: '', label: 'All' },
                                                                { value: '1', label: 'Active' },
                                                                { value: '0', label: 'Inactive' },
                                                            ]}
                                                            styles={customStyles}
                                                            className="mr-2"
                                                            placeholder="Status"
                                                        />
                                                        <button
                                                            onClick={() => FetchData(selectedLevel)}
                                                            className=" bg-[var(--colorPrimary)] rounded-md text-white px-4 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none hasDatepicker"
                                                        >
                                                            Apply Filters
                                                        </button>
                                                        <button
                                                            onClick={resetFilters}
                                                            className=" bg-[var(--colorPrimary)] rounded-md text-white px-4 py-2 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none hasDatepicker"
                                                        >
                                                            Reset Filters
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="overflow-x-auto">
                                                <div className="inline-block min-w-full rounded-5xl overflow-hidden ">
                                                    <table className="table-auto w-full p-0 dataTable no-footer" id="myTeamLeft">
                                                        <thead>
                                                            <tr className="text-body">
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left rounded-tl-xl rounded-bl-xl">Sr.</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Member Code</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Sponsor</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Joining Date</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Level</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Activation Date</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Wallet Address</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Strong Leg</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Working Package</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Non-Working Package</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Rank Name</th>
                                                                <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Bonanza</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {teamData?.data?.length > 0 ? (
                                                                teamData?.data?.map((x, i) => (
                                                                    <tr key={x?.uid || i}>
                                                                        <td style={{ whiteSpace: "nowrap" }}>{(currentPage - 1) * limit + i + 1}</td>
                                                                        <td>{Change(x?.username)}</td>
                                                                        <td>{x?.sponsor_username}</td>
                                                                        <td>{x?.joining_date ? moment(x?.joining_date).format('MMM Do YY') : 'N/A'}</td>
                                                                        <td>{x?.level}</td>
                                                                        <td>{x?.Activation_date ? moment(x?.Activation_date).format('MMM Do YY') : '-'}</td>
                                                                        <td>{x?.wallet_address}</td>
                                                                        <td>
                                                                            {x?.isStrongLeg == true
                                                                                ? "Yes"
                                                                                : x?.isStrongLeg == false
                                                                                    ? "No"
                                                                                    : "-"}
                                                                        </td>
                                                                        <td>{x?.self_investment}</td>
                                                                        <td>{x?.self_investmentNonWorking ? x?.self_investmentNonWorking : 0}</td>
                                                                        <td>{x?.current_rank ? x.current_rank : "-"}</td>
                                                                        <td>{x?.current_bonanza ? x.current_bonanza : "-"}</td>
                                                                      
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td className="text-sm text-center px-4 py-3" colSpan={11}>No Data Found.</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
