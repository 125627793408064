export default function ArrayToObject(walletArray) {
try{
        const walletObject = {};
    walletArray.forEach(wallet => {
        walletObject[wallet.slug] = wallet;
    });
    return walletObject;
}catch(e){
    console.error("ArrayToObject",ArrayToObject)
}
}