import { createSlice } from '@reduxjs/toolkit';

export const dashboardDataSlice = createSlice({
  name: 'dashboardData', // Corrected the name to match with the reducer key
  initialState: {
    teamsData: [],
    ranksData: [],
    walletsData: [],
    profileData: []
  },
  reducers: {
    setTeamsData: (state, action) => {
      state.teamsData = action.payload; 
    },
    setRanksData: (state, action) => {
      state.ranksData = action.payload; 
    },
    setWalletsData: (state, action) => {
      state.walletsData = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
  },
});

export const { setTeamsData,setRanksData,setWalletsData,setProfileData } = dashboardDataSlice.actions;
export default dashboardDataSlice.reducer;
