import { createSlice } from '@reduxjs/toolkit';
export const rateSlice = createSlice({
    name: 'rate',
    initialState: {
        price: null,
    },
    reducers: {
        setRate: (state, action) => {
            state.price = action.payload;
        },
    },
});
export const { setRate } = rateSlice.actions;
export default rateSlice.reducer;
