import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import DefiCoin from "./../../images/DeFi_Coin.webp";
import Usdt from "./../../images/usdt.webp";
import DefiWorldIcon from "./../../images/defiworldicon.webp";
import "./Packages.css";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import { ethers } from "ethers";
import Change from "../../Common/StringToSub";
import { useSelector } from "react-redux";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
import ContractDetails from "../../Contracts/ContractDetails";
import USDTIncreaseAllowance from "../../Common/USDTIncreaseAllowance";
import Loader from "../../Components/Loader/Loader";
import ConnectButton from "../../Components/ConnectButton";
import moment from "moment";
import axios from "axios";
import { setRate } from './../../Redux/rateSlice';
import { useDispatch } from 'react-redux';
import TokenLiveRate from "../../Common/TokenLiveRate";
import Pagination from "../../Components/PaginationControls/PaginationControls";
import {
  setProfileData,


} from "../../Redux/DashboardDataSlice";
export default function Packages() {
  const { AxiosGet, AxiosPost } = useAxiosHelper([]);
  const rate = useSelector((state) => state.rate.price);
  const dispatch = useDispatch();
  const [formAmount, setFormAmount] = useState('');
  const [maxReturn, setMaxReturn] = useState(0);
  const [tokenGet, setTokenGet] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [packages, setPackages] = useState([]);
  const [payerTokenAmount, setPayerTokenAmount] = useState([]);
  const [tokenAmount, setTokenAmount] = useState([]);
  const profileData = useSelector((state) => state?.dashboardData?.profileData);
  const FetchData = async () => {
    try {
      const tempProfile =
        await
          AxiosGet(ApiPaths.getProfile)
      dispatch(setProfileData(tempProfile));
    } catch (e) {
      console.error("Error fetching data", e);
    }
  };
  const fetchOrders = async () => {
    try {

      const queryParams = { status: 1, };

      const queryString = new URLSearchParams(queryParams).toString();

      const response = await AxiosGet(`${ApiPaths.getOrders}?${queryString}`);
      setOrders(response?.data || []);
      BasicInfo.isDebug && console.log(response?.data, "response of orders");

      if (response) {
        toastSuccess(response?.message);
        setTotalPages(response?.totalPages || 1);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  const PaybleAmountNonWorking = async () => {
    console.log("PaybleAmountNonWorking")
    try {
      const { ethereum } = window;
      if (ethereum) {
        const newAmount = ethers.utils.parseUnits(formAmount.toString(), 6);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();

        const busdInstance = new ethers.Contract(
          ContractDetails.contract,
          ContractDetails.contractABI,
          signer
        );
        console.log("busdInstance", busdInstance)
        let inc = await busdInstance.getPayableAmountForNonWorkingOrder(
          newAmount
        );
        setTokenAmount(inc.toString())
        console.log("inc", inc.toString())
      }
    } catch (error) {
      toastFailed("Transaction Failed.");
      BasicInfo.isDebug && console.log("Transaction Failed error11 ", error);
      return false;
    }
  };
  const PaybleAmountWorking = async (payableamount) => {
    console.log("PaybleAmountWorking",payableamount)
    try {
      const { ethereum } = window;
      if (ethereum) {
        const newAmount = ethers.utils.parseUnits(payableamount.toString(), 6);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();

        const busdInstance = new ethers.Contract(
          ContractDetails.contract,
          ContractDetails.contractABI,
          signer
        );
        console.log("busdInstance", busdInstance)
        let inc = await busdInstance.getPayableAmountForWorkingOrder(
          newAmount
        );
        setPayerTokenAmount(inc?.payerTokenAmount)
        setTokenAmount(inc?.tokenAmount)

        // setInc(inc.toString())
        console.log("inc", inc)
      }
    } catch (error) {
      toastFailed("Transaction Failed.");
      BasicInfo.isDebug && console.log("error11", error);
      return false;
    }
  };
  const fetchRate = async () => {
    try {
      const tempPrice = await TokenLiveRate();
      dispatch(setRate(tempPrice))
    } catch (error) {
      console.error('Error fetching the rate:', error);
    }
  };
  const acc = useSelector((state) => state.account.value);
  // const inputHandleChange = (amount) => {
  //   BasicInfo.isDebug && console.log(amount);
  //   setFormAmount(amount);

  //   if (amount > 0) {
  //     const selectedPkg = packages.find(pkg => pkg?.package?.planId == selectedPackage);
  //     if (selectedPkg?.planId == 1) {
  //       console.log("payableNot working")
  //       PaybleAmountNonWorking(); // Call for non-working plans
  //     } else if (selectedPkg?.planId == 2) {
  //       console.log("payable working")
  //       PaybleAmountWorking(); // Call for working plans
  //     }

  //     setMaxReturn(amount * 3);
  //     setTokenGet(parseFloat(amount / BasicInfo.tokenPrice).toFixed(2));
  //   } else {
  //     setTokenGet(0);
  //     setMaxReturn(0);
  //   }
  // };
  const inputHandleChange = (amount) => {
    setFormAmount(amount); // Only update the state here
  };

  useEffect(() => {
    if (formAmount > 0) {
      const selectedPkg = packages.find(pkg => pkg?.planId == selectedPackage);
      if (selectedPkg?.planId == 1) {
        PaybleAmountNonWorking(); // Call for non-working plans
      } else if (selectedPkg?.planId == 2) {
        PaybleAmountWorking(formAmount); // Call for working plans
      }
      setMaxReturn(formAmount * 3); // Set max return based on the current formAmount
      setTokenGet(parseFloat(formAmount / BasicInfo.tokenPrice).toFixed(2)); // Calculate token get
    } else {
      setTokenGet(0);
      setMaxReturn(0);
    }
  }, [formAmount, selectedPackage]); // Run when formAmount or selectedPackage changes

  //   let debounceTimeout;
  // const inputHandleChange = (amount) => {
  //   clearTimeout(debounceTimeout);
  //   debounceTimeout = setTimeout(() => {
  //     BasicInfo.isDebug && console.log(amount);
  //     setFormAmount(amount);
  //     const selectedPkg = packages.find(pkg => pkg?.package?.name == selectedPackage);

  //     if (selectedPkg?.planId == 1) {
  //       console.log("payableNot working");
  //       PaybleAmountNonWorking(); // Call for non-working plans
  //     } else if (selectedPkg?.planId == 2) {
  //       console.log("payable working");
  //       PaybleAmountWorking(); // Call for working plans
  //     }

  //     if (amount > 0) {
  //       setMaxReturn(amount * 3);
  //       setTokenGet(parseFloat(amount / BasicInfo.tokenPrice).toFixed(2));
  //     } else {
  //       setTokenGet(0);
  //       setMaxReturn(0);
  //     }
  //   }, 500); // 2-second delay
  // };

  const addUSDTToWallet = async (
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
    tokenImage
  ) => {
    BasicInfo.isDebug && console.log("Adding token to wallet...");
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        const result = await provider.send("wallet_watchAsset", {
          type: "ERC20",
          options: {
            address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
            symbol: "USDT",
            decimals: "6",
          },
        });
        if (result) {
          BasicInfo.isDebug && console.log(`USDT successfully added to wallet!`);
        } else {
          BasicInfo.isDebug && console.log(`Failed to add USDT to wallet.`);
        }
      } catch (error) {
        console.error("Error adding token to wallet:", error);
      }
    } else {
      BasicInfo.isDebug && console.log(
        "MetaMask is not installed. Please install MetaMask and try again."
      );
    }
  };
  const addDWCToWallet = async (
    tokenAddress,
    tokenSymbol,
    tokenDecimals,
    tokenImage
  ) => {
    BasicInfo.isDebug && console.log("Adding token to wallet...");
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        const result = await provider.send("wallet_watchAsset", {
          type: "ERC20",
          options: {
            address: "0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F",
            symbol: "DWC",
            decimals: "18",
          },
        });
        if (result) {
          BasicInfo.isDebug && console.log(`DWC successfully added to wallet!`);
        } else {
          BasicInfo.isDebug && console.log(`Failed to add DWC to wallet.`);
        }
      } catch (error) {
        console.error("Error adding token to wallet:", error);
      }
    } else {
      BasicInfo.isDebug && console.log(
        "MetaMask is not installed. Please install MetaMask and try again."
      );
    }
  };
  const DepositFunc = async () => {
    // return DepositConfirm("42106155");
    // console.log(profileData?.wallet_address, "mmmmmmm")
    BasicInfo.isDebug && console.log("working");
    if (acc != profileData?.wallet_address) {
      toastFailed("Please use same wallet used at the time of registration");
      return;
    }

    if (!formAmount > 0) {
      toastFailed("Please enter valid amount");
      return;
    }
    try {
      setLoading(true);
      const body = {
        planId: selectedPackage,
        amount: formAmount,
      };
      console.log("BODY", body)
      const tempDepositData = await AxiosPost(ApiPaths.placeOrder, body);
      // toastSuccess(tempDepositData?.message);
      // setLoading(false)
      BasicInfo.isDebug && console.log("tempDepositData", tempDepositData);
      Stake(formAmount, tempDepositData?.orderDetail?.order_Id);
      fetchOrders()
    } catch (error) {
      BasicInfo.isDebug &&
        console.error("Error fetching wallet details:", error);
      toastFailed(error?.response?.data?.message);
      setLoading(false);
    }
  };

  async function Stake(amount, orderId) {
    BasicInfo.isDebug && console.log("Staking initiated", { amount, orderId });
    setLoading(true);

    try {
      const { ethereum } = window;
      if (!ethereum) {
        alert("Ethereum wallet not found. Please install MetaMask.");
        return;
      }

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      let allowanceGranted = false;

        BasicInfo.isDebug && console.log("Processing working plan",payerTokenAmount);

        const newAmount = ethers.utils.parseUnits(formAmount.toString(), 6);
        const payerAllowanceGranted = await USDTIncreaseAllowance(newAmount, ContractDetails.BUSD);

        if (!payerAllowanceGranted) {
          // toastFailed("Allowance failed for working plan.");
          toastFailed("Allowance failed .");
          setLoading(false);
          return;
        }
        allowanceGranted = true;
      

      if (allowanceGranted) {
        const contractInstance = new ethers.Contract(
          ContractDetails.contract,
          ContractDetails.contractABI,
          signer
        );

          const tx = await contractInstance.WorkingOrderAndPay(newAmount, orderId, {
            value: ethers.utils.parseEther("0"), // Set to zero since this is an ERC-20 token transfer
          });

          // // Call update_tx_hash API here before waiting for the transaction
          // const txHash = tx.hash; // Get the transaction hash
          // await updateTxHashAPI(txHash, orderId); // Call your API with the transaction hash and orderId

          const receipt = await tx.wait();
          BasicInfo.isDebug && console.log("Transaction successful", receipt);
          DepositConfirm(receipt?.blockNumber);
        
      } else {
        toastFailed("Allowance not granted. Staking failed.");
        setLoading(false);
      }

    } catch (error) {
      toastFailed("Transaction failed. Please try again.");
      BasicInfo.isDebug && console.log("Transaction error", error);
      setLoading(false);
    }
  }
  // function for calling the update_tx_hash API
  const updateTxHashAPI = async (txHash, orderId) => {
    try {
      const body = {
        tx_hash: txHash,
        order_Id: orderId,
      };
      console.log("Body===>", body)
      const response = await AxiosPost(ApiPaths.updateTxHash, body); // Use your existing AxiosPost method
      console.log('Transaction hash updated successfully:', response?.message);
    } catch (error) {
      BasicInfo.isDebug && console.error('Error updating transaction hash:', error);
      toastFailed(error?.response?.data?.message || 'Failed to update transaction hash');
    }
  };

  const DepositConfirm = async (blockNum) => {
    BasicInfo.isDebug && console.log("working");
    try {
      setLoading(true);
      const body = {
        blockNumber: blockNum,
      };
      const confirmDepositData = await AxiosPost(ApiPaths.confirmOrder, body);
      toastSuccess(confirmDepositData?.message);
      BasicInfo.isDebug && console.log("confirmDepositData", confirmDepositData);
    } catch (error) {
      BasicInfo.isDebug &&
        console.error("Error fetching wallet details:", error);
      toastFailed(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // const handlePackageChange = (event) => {
  //   const selectedPackageName = event.target.value;
  //   setSelectedPackage(selectedPackageName);


  //   if (!formAmount || formAmount <= 0) {
  //     console.log("Amount must be greater than 0");
  //     const selectedPkg = packages.find(pkg => pkg?.package?.name == selectedPackageName);

  //     if (selectedPkg?.planId == 1) {
  //       console.log("payableNot working");
  //       PaybleAmountNonWorking(); // Call for non-working plans
  //     } else if (selectedPkg?.planId == 2) {
  //       console.log("payable working");
  //       PaybleAmountWorking(); // Call for working plans
  //     }
  //   }


  // };

  const handlePackageChange = (event) => {
    const selectedPackageName = event.target.value;
    setSelectedPackage(selectedPackageName);
    console.log(selectedPackageName, "<===selected Package Name")
    // First, check if the formAmount is valid and greater than 0
    if (!formAmount || formAmount <= 0) {
      console.log("Amount must be greater than 0");
      return;
    }

    // Now proceed with finding the selected package and calling the corresponding functions
    const selectedPkg = packages.find(pkg => pkg?.package?.planId == selectedPackageName);

    if (selectedPkg?.planId == 1) {
      // console.log(selectedPackage,"ppppppppp")
      console.log("Not working package");
      PaybleAmountNonWorking();
    } else if (selectedPkg?.planId == 2) {
      console.log("working package");
      PaybleAmountWorking();
    }
  };
  const fetchPackages = async () => {
    // console.log("Packages")
    try {
      // console.log("Packages")
      const response = await AxiosGet(ApiPaths.getPackages); // Replace with actual API endpoint
      BasicInfo.isDebug && console.log(response, "Packages.......");
      setPackages(response?.packages); // Assuming packages are in response.data.packages
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };
  useEffect(() => {
    fetchPackages();
    fetchOrders();
    fetchRate()
    FetchData()
  }, []);

  return (
    <div>
      {loading && <Loader />}
      <div
        id="loading"
        className="bg-gradient-to-b from-black to-black w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full flex items-center justify-center z-50"
        style={{ background: "black", display: "none" }}
      >
        <div className="fancy-spinner">
          <div className="ring" />
          <div className="ring" />
          <div className="dot">
            <img
              src={DefiCoin}
              width="150px"
              height="150px"
              className="h-full w-full object-contain"
              alt="Defi World Logo"
            />
          </div>
        </div>
      </div>
      <div id="mobile" className="demo2 relative">
        <div id="mobileBodyContent">
          <Header />
          <div className="inner-content px-4 2xl:px-3 mt-5">
            <section className="py-0">
              <div className="container mx-auto">
                <div className="flex flex-wrap">
                  <div className="contentBox mx-auto overflow-hidden w-full">
                    <section className="card-data-section mt-10 lg:mt-5">
                      <div className style={{ marginBottom: "50px" }}>
                        <div className="flex flex-wrap justify-center items-center gap-10 mb-20">
                          <div className="max-w-lg w-full relative">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-[620px] absolute top-[-7%] left-[-7%] filter-light  sm:hidden z-10"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              id="Layer_2"
                              data-name="Layer 2"
                              viewBox="0 0 135.45 232.4"
                            >
                              <defs>
                                <style
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      "\n                                            .cls-1 {\n                                                fill: #006f76;\n                                            }\n\n                                            .cls-1,\n                                            .cls-2,\n                                            .cls-3 {\n                                                stroke-width: 0px;\n                                            }\n\n                                            .cls-2 {\n                                                fill: #01ffff;\n                                            }\n\n                                            .cls-3 {\n                                                fill: none;\n                                            }\n\n                                            .cls-4 {\n                                                clip-path: url(#clippath);\n                                            }\n                                        ",
                                  }}
                                />
                                <clipPath id="clippath">
                                  <polygon
                                    className="cls-3"
                                    points="0 0 135.45 0 55.44 232.4 0 230.5 0 0"
                                  />
                                </clipPath>
                              </defs>
                              <g id="Layer_2-2" data-name="Layer 2">
                                <g className="cls-4">
                                  <polygon
                                    className="cls-2"
                                    points="3.54 45.72 3.54 18.81 11.12 18.81 11.12 50.92 3.54 45.72"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="50.25 11.23 3.54 11.23 3.54 18.81 55.44 18.81 50.25 11.23"
                                  />
                                  <polyline
                                    className="cls-2"
                                    points="46.06 15.02 46.06 18.81 94.77 18.81 92.17 15.02"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="102.61 15.02 97.9 15.02 100.5 18.81 105.2 18.81 102.61 15.02"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="112 15.02 107.29 15.02 109.89 18.81 114.6 18.81 112 15.02"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="121.39 15.02 116.69 15.02 119.28 18.81 123.99 18.81 121.39 15.02"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="38.03 226.04 11.12 226.04 11.12 218.46 43.23 218.46 38.03 226.04"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="3.54 179.33 3.54 226.04 11.12 226.04 11.12 174.13 3.54 179.33"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="7.33 137.4 7.33 183.52 11.12 183.52 11.12 134.81 7.33 137.4"
                                  />
                                  <path
                                    className="cls-1"
                                    d="m498.57,219.42H10.15V17.84h488.42v201.58Zm-486.49-1.93h484.56V19.77H12.08v197.72Z"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="7.65 87.43 6.09 86.27 6.09 44.92 7.65 44.92 7.65 87.43"
                                  />
                                </g>
                              </g>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-[620px] absolute top-[-5%] filter-light right-[-7%] sm:hidden z-10"
                              style={{ transform: "rotate(180deg)" }}
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              id="Layer_2"
                              data-name="Layer 2"
                              viewBox="0 0 135.45 232.4"
                            >
                              <defs>
                                <style
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      "\n                                                .cls-1 {\n                                                    fill: #006f76;\n                                                }\n\n                                                .cls-1,\n                                                .cls-2,\n                                                .cls-3 {\n                                                    stroke-width: 0px;\n                                                }\n\n                                                .cls-2 {\n                                                    fill: #01ffff;\n                                                }\n\n                                                .cls-3 {\n                                                    fill: none;\n                                                }\n\n                                                .cls-4 {\n                                                    clip-path: url(#clippath);\n                                                }\n                                            ",
                                  }}
                                />
                                <clipPath id="clippath">
                                  <polygon
                                    className="cls-3"
                                    points="0 0 135.45 0 55.44 232.4 0 230.5 0 0"
                                  />
                                </clipPath>
                              </defs>
                              <g id="Layer_2-2" data-name="Layer 2">
                                <g className="cls-4">
                                  <polygon
                                    className="cls-2"
                                    points="3.54 45.72 3.54 18.81 11.12 18.81 11.12 50.92 3.54 45.72"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="50.25 11.23 3.54 11.23 3.54 18.81 55.44 18.81 50.25 11.23"
                                  />
                                  <polyline
                                    className="cls-2"
                                    points="46.06 15.02 46.06 18.81 94.77 18.81 92.17 15.02"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="102.61 15.02 97.9 15.02 100.5 18.81 105.2 18.81 102.61 15.02"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="112 15.02 107.29 15.02 109.89 18.81 114.6 18.81 112 15.02"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="121.39 15.02 116.69 15.02 119.28 18.81 123.99 18.81 121.39 15.02"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="38.03 226.04 11.12 226.04 11.12 218.46 43.23 218.46 38.03 226.04"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="3.54 179.33 3.54 226.04 11.12 226.04 11.12 174.13 3.54 179.33"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="7.33 137.4 7.33 183.52 11.12 183.52 11.12 134.81 7.33 137.4"
                                  />
                                  <path
                                    className="cls-1"
                                    d="m498.57,219.42H10.15V17.84h488.42v201.58Zm-486.49-1.93h484.56V19.77H12.08v197.72Z"
                                  />
                                  <polygon
                                    className="cls-2"
                                    points="7.65 87.43 6.09 86.27 6.09 44.92 7.65 44.92 7.65 87.43"
                                  />
                                </g>
                              </g>
                            </svg>
                            <div className="py-3 px-4 md:px-6 pt-28 pb-10 rounded-sm  relative shadow-inner mb-10 h-full historyhead z-40">
                              <h3 className="card-title text-xl xl:text-2xl mb-0 lg:mb-6 text-white font-medium font-play tracking-wide textdefibold absolute left-2/4 w-full -translate-x-2/4 text-center top-[7%]">
                                Package Activation
                              </h3>
                              <div
                                id="processPackage"
                                className="py-0 mx-auto textdefibold "
                                // action="https://defiworld.ai/handlePackageTransaction"
                                name="processPackage"
                                method="POST"
                              >
                                <div className="block mb-7 ">
                                  <div className="flex items-center pl-3 py-0 border rounded-lg border-1 border-[#999999]">
                                    <span className="flex items-center pr-3 border-r-0 border-gray-300 border-opacity-10">
                                      <i className="la la-money-bill text-[#00ffff] text-xl leading-8" />
                                    </span>
                                    <input
                                      className="flex-grow outline-none px-4 py-3 w-full shadow-none leading-7 bg-stone-700 bg-opacity-10 roundedx-lg text-white text-xs tracking-widest text-opacity-75 border-0"
                                      id="inAmount"
                                      type="number"
                                      name="amount"
                                      value={formAmount}
                                      onChange={(e) =>
                                        inputHandleChange(e.target.value)
                                      }
                                      placeholder={0.0}
                                      required
                                    />
                                  </div>
                                  <div className="block mb-7 mt-2">
                                    <select className="select-element"
                                      value={selectedPackage} onChange={handlePackageChange} >
                                      <option value="">Select Package</option>
                                      <option value="2">Working Activation</option>
                                      {/* {packages.map((pkg, index) => (
                                        <option key={pkg.packageId || index}
                                          value={pkg?.planId}
                                        >{pkg?.package?.name}</option>))} */}
                                    </select>
                                  </div>
                                </div>
                                <div className="space-y-5 space-y-6 text-xs lg:text-sm text-white font-normal tracking-wide mt-4 md:mt-5">
                                  <div className="flex items-center justify-between">
                                    <span>Monthly Bonus (%)</span>
                                    <span className="text-right min-w-[100px]">
                                      {selectedPackage
                                        ? packages.find((pkg) => pkg.planId == selectedPackage)?.roi_income?.income
                                        : "0 "} %
                                    </span>   </div>
                                  <div className="flex items-center justify-between">
                                    <span>Bonus Return</span>
                                    <span className="text-right min-w-[100px]">
                                      {selectedPackage
                                        ? packages.find((pkg) => pkg.planId == selectedPackage)?.package?.total_capping
                                        : "0"} %
                                    </span>   </div>
                                  {/* <div className="flex items-center justify-between">
                                    <span>Package Return</span>
                                    <span className="text-right min-w-[100px]">      300%     </span>   </div> */}
                                  {/* <div className="flex items-center justify-between">
                                    <span>Package Duration</span>
                                    <span className="text-right min-w-[100px]">
                                      {selectedPackage
                                        ? packages.find((pkg) => pkg.planId == selectedPackage)?.roi_income?.duration || "0"
                                        : "0"} Months
                                    </span>   </div> */}
                                  {/* <div className="flex items-center justify-between">
                                    <span>Max Return ($)</span>
                                    <span
                                      className="text-right min-w-[100px]"
                                      id="package-return-max" >
                                      {selectedPackage
                                        ? (formAmount *
                                          (packages.find((pkg) => pkg.planId == selectedPackage)?.package?.total_capping / 100)
                                        ).toFixed(2)
                                        : "0.00"} $
                                    </span> </div> */}
                                  {selectedPackage == 2 ? (
                                    <>
                                      {/* Show USDT if planId is 2 */}
                                      <div className="flex items-center justify-between">
                                        <span>USDT</span>
                                        <span className="text-right min-w-[100px]" id="usdt-amount">
                                          {/* Render the payerTokenAmount or default value */}
                                          {/* {(payerTokenAmount ? (payerTokenAmount / 1e6).toFixed(2) : '00.00')} */}
                                          {/* {(formAmount ? (formAmount / 1e6).toFixed(2) : '00.00')} */}
                                          {(parseFloat(formAmount) || 0).toFixed(2)}
                                        </span>
                                      </div>
                                      {/* Show DWC if planId is 2 */}
                                      {/* <div className="flex items-center justify-between">
                                        <span>DWC</span>
                                        <span className="text-right min-w-[100px]" id="dwc-amount-">
                                          // Render the tokenAmount or default value
                                          {(tokenAmount ? (tokenAmount / 1e18).toFixed(2) : '00.00')}
                                        </span>
                                      </div>  */}
                                       </>) : (<>
                                        {/* Show only DWC if planId is not 2 */}
                                        {/* <div className="flex items-center justify-between">
                                          <span>DWC</span>
                                          <span className="text-right min-w-[100px]" id="dwc-amount-">
                                            // Render the inc value or default
                                            {(tokenAmount ? (tokenAmount / 1e18).toFixed(2) : '00.00')}
                                          </span>
                                        </div>  */}
                                        <div className="flex items-center justify-between">
                                        <span>USDT</span>
                                        <span className="text-right min-w-[100px]" id="usdt-amount">
                                          {/* Render the payerTokenAmount or default value */}
                                          {/* {(payerTokenAmount ? (payerTokenAmount / 1e6).toFixed(2) : '00.00')} */}
                                          {/* {(formAmount ? (formAmount / 1e6).toFixed(2) : '00.00')} */}
                                          {"00.00"}
                                        </span>
                                      </div>
                                         </>)}
                                </div>
                                <div className="flex items-center justify-between p-0 mt-10">
                                  {acc ? (
                                    <button
                                      className="bg-gradient-to-br from-primary/90 to-primary/60 rounded-md py-3 text-sm w-full hover:bg-secondary text-white transition duration-200 flex items-center justify-center mx-auto"
                                      type="button"
                                      onClick={DepositFunc}
                                      name="btnSubmit" >
                                      <span>Process</span>{" "}
                                      <i className="las la-paper-plane text-2xl leading-6 ml-2" />
                                    </button>) : (<ConnectButton />)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Package History */}
                        {orders.length > 0 ? (
                          <>
                            <div className="overflow-x-auto mb-50">
                              <table className="min-w-full bg-white border-collapse shadow rounded-lg overflow-hidden">
                                <thead className="bg-gray-800 text-white">
                                  <tr>
                                    <th className="py-2 px-4 border-b border-gray-300 whitespace-nowrap">Date</th>
                                    <th className="py-2 px-4 border-b border-gray-300 whitespace-nowrap">Package ID</th>
                                    <th className="py-2 px-4 border-b border-gray-300 whitespace-nowrap">Amount</th>
                                    <th className="py-2 px-4 border-b border-gray-300 whitespace-nowrap">Status</th>
                                  </tr>
                                </thead>
                                <tbody style={{ background: "#151616", textAlign: "center" }}>
                                  {orders
                                    .filter((order) => order.status === 1)
                                    .map((order, index) => (
                                      <tr key={order.orderId || index}>
                                        <td className="py-2 px-4 border-b border-gray-300 TableData">
                                          {moment(order.createdAt).format("MMM Do YY")}
                                        </td>
                                        <td className="py-2 px-4 border-b border-gray-300 TableData">
                                          {order.planId}
                                        </td>
                                        <td className="py-2 px-4 border-b border-gray-300 TableData">
                                          {order.amount}
                                        </td>
                                        <td
                                          className={`py-2 px-4 border-b border-gray-300 TableData ${order.status === 0
                                            ? "status-pending"
                                            : order.status === 1
                                              ? "status-success"
                                              : "status-rejected"
                                            }`}
                                        >
                                          {order.status === 0
                                            ? "Pending"
                                            : order.status === 1
                                              ? "Success"
                                              : "Rejected"}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>

                            </div>
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          </>
                        ) : (
                          // <p className="text-center text-gray-500 mt-4">No orders found</p> 
                          null
                        )}
                      </div>
                    </section>
                    <div className="data-card container mx-auto pb-20 px-6 2xl:px-0">
                      <div className="mx-auto mb-5 flex flex-wrap flex-col items-center justify-center 2xl:justify-between flex-wrap">
                        <div className="flex flex-wrap gap-5 justify-center md:justify-[none] mb-4">
                          <div className="title text-lg font-medium text-white mb-3 tracking-wider mb-5 md:mb-0 text-center border-b-2 border-[#13f6ff]">
                            DWC Price : <span>{rate !== null ? `$ ${rate}` : 'Loading...'}</span>
                          </div>
                        </div>
                        <div className="flex flex-wrap gap-2 sm:gap-16 justify-center md:justify-[none] mt-5 2xl:mt-0">
                          <button
                            onClick={addUSDTToWallet}
                            className="flex justify-center items-center btnMU px-2 hover:text-[#2adbff] transition-[0.5s] z-10"
                            // onclick="addUsdtToken();"
                            type="button"
                          >
                            <img
                              src={Usdt}
                              width={48}
                              height={48}
                              className="h-5 w-5 max-h-5 max-w-5 mr-1"
                              alt="Add USDT"
                            />
                            <span>Add USDT</span>
                          </button>

                          <button
                            onClick={addDWCToWallet}
                            className="flex justify-center items-center btnMU px-2 hover:text-[#2adbff] transition-[0.5s] z-10"
                            // onclick="addDwcToken();"
                            type="button"
                          >
                            <img
                              src={DefiWorldIcon}
                              width={48}
                              height={48}
                              className="h-5 w-5 max-h-5 max-w-5 mr-1"
                              alt="Add DWC"
                            />
                            <span>Add DWC</span>
                          </button>

                          <button
                            className="block btnMU px-2 hover:text-[#2adbff] transition-[0.5s] z-10"
                            onclick="doConnect();"
                            type="button"
                          >
                            <span className="connectWallet">{Change(acc) ? Change(acc) :
                              "Wallet Address"
                            }
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="p-3 md:p-6 rounded-md border border-[#64ffff] bg-[#13364f] relative shadow-inner mb-10 mt-10 max-w-lg w-full mx-auto">
                        <div className="container mx-auto text-left">
                          {/* <li className="mb-3 text-base text-white leading-6 tracking-wide">
                            Amount should be divisible by 10 like
                            100,200,500,1000,2000,3000.
                          </li> */}
                          <p className=" text-base text-white leading-6 tracking-wide">
                            The minimum investment amount is $50,
                            with no upper limit on the maximum investment amount.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div >
      </div >
      <Footer />
    </div >
  );
}