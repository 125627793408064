// import React, { useEffect, useState } from 'react';
// import Header from '../../Components/Header';
// import Footer from '../../Components/Footer';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { ApiPaths } from '../../Config/ApiPaths';
// import useAxiosHelper from '../../Common/AxiosHalper';
// import moment from 'moment';
// import Pagination from '../../Components/PaginationControls/PaginationControls';

// export default function IncomeOverview() {
//     const initialBonuses = [
//         { title: "Monthly Rewards", value: "roi_income" },
//         { title: "Level Bonus", value: "roi_level_income" },
//         { title: "Referal Bonus", value: "level_income" },
//         { title: "Rank Reward", value: "reward_income" },
//     ];
//     const [activeTab, setActiveTab] = useState(initialBonuses[0]);
//     const [data, setData] = useState({});
//     const [bonuses, setBonuses] = useState(initialBonuses);
//     const [totalPages, setTotalPages] = useState(0);
//     const [currentPage, setCurrentPage] = useState(1);
//     const navigate = useNavigate();
//     const location = useLocation();
//     const { AxiosGet } = useAxiosHelper([]);

//     useEffect(() => {
//         const params = new URLSearchParams(location.search);
//         const tab = params.get('tab');
//         const activeTab = bonuses.find(bonus => bonus.value === tab) || bonuses[0];
//         setActiveTab(activeTab);
//         fetchData(activeTab.value);
//     }, [location.search, bonuses, currentPage]);

//     useEffect(() => {
//         console.log(data, "DATAAAAA")
//     }, [data])

//     const fetchData = async (tab) => {
//         try {
//             let response;
//             if (tab === 'reward_income') {
//                 response = await AxiosGet(ApiPaths.getRanks);
//                 setData(prevData => ({
//                     ...prevData,
//                     [tab]: response || []  // Consistently use response.data
//                 }));
//             } else {
//                 const queryParams = { source: tab, limit: 10 };
//                 const queryString = new URLSearchParams(queryParams).toString();
//                 response = await AxiosGet(`${ApiPaths.getPaymentTransaction}?${queryString}`);
//                 setTotalPages(response?.totalPages || 1)
//                 setData(prevData => ({
//                     ...prevData,
//                     [tab]: response.data || []  // Consistently use response.data
//                 }));
//             }
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };
//     const handleTabClick = (tab) => {
//         setActiveTab(tab);
//         navigate(`?tab=${tab.value}`);
//     };

//     const renderTable = (tab) => {
//         const tabData = data[tab.value] || [];
//         const columns = {
//             "roi_income": ["Amount", "From", "Level", "Date"],
//             "roi_level_income": ["Amount", "From", "Level", "Date"],
//             "level_income": ["Amount", "From", "Level", "Date"],
//             "reward_income": ["Rank", "Direct Sale", "Group Sale", "Rank Requirement", "Active User", "Bonous", "Status"] // Updated columns
//         };

//         return (
//             <div>
//                 <div className="title text-lg font-medium text-white mb-3 tracking-wider">{tab.title}</div>
//                 <div className="overflow-x-auto">
//                     <div className="inline-block min-w-full rounded-5xl overflow-hidden">
//                         <table className="table-auto w-full">
//                             <thead>
//                                 <tr className="text-body">
//                                     <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Sr.</th>
//                                     {columns && columns[tab.value] ? (
//                                         columns[tab.value].map((col, index) => (
//                                             <th key={index} className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">
//                                                 {col}
//                                             </th>
//                                         ))
//                                     ) : null}
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {tabData.length > 0 ? tabData.map((item, index) => (
//                                     <tr key={index} className="border-b border-gray-100 border-opacity-10 font-medium text-xs tracking-wider whitespace-pre">
//                                         <td className="text-left px-4 py-3">{index + 1}</td>
//                                         {columns[tab.value].map((col, colIndex) => {
//                                             let cellValue;
//                                             switch (col) {
//                                                 case "Rank":
//                                                     cellValue = `${item.rankname}`;
//                                                     break;
//                                                 case "Direct Sale":
//                                                     cellValue = `${item?.DirectBusiness}/${(item?.requiredDirectBusiness)}`;
//                                                     break;
//                                                 case "Group Sale":
//                                                     cellValue = `${item?.business}/${(item?.required_business)}`;
//                                                     break;
//                                                 case "Rank Requirement":
//                                                     cellValue = item?.required_ranks
//                                                         ? `${item.required_ranks} x ${item.requiredRankCount}`
//                                                         : "-";
//                                                     break;
//                                                 case "Active User":
//                                                     cellValue = `${item.directReferrals} / ${item.requiredDirect}`;
//                                                     break;
//                                                 case "Bonous":
//                                                     cellValue = item.reward_income; // Show "-" if it's the same
//                                                     break;
//                                                 case "Status":
//                                                     cellValue = item.status === 1 ? 'Achieved' : 'Pending';
//                                                     break;
//                                                 case "Amount":
//                                                     cellValue = item.amount ? (item.amount).toFixed(2) : '0';
//                                                     break;
//                                                 case "Date":
//                                                     cellValue = moment(item.time).format("Do MMM YY");
//                                                     break;
//                                                 default:
//                                                     cellValue = item[col.replace(/\s+/g, '_').toLowerCase()]; // For other columns
//                                                     break;
//                                             }

//                                             return (
//                                                 <td key={colIndex} className="text-left px-4 py-3">
//                                                     {cellValue}
//                                                 </td>
//                                             );
//                                         })}
//                                     </tr>
//                                 )) : (
//                                     <tr className="border-b border-gray-100 border-opacity-10 font-medium text-xs tracking-wider whitespace-pre">
//                                         <td className="text-left px-4 py-3" colSpan={columns && columns[tab.value] ? columns[tab.value].length + 1 : 1}>
//                                             No data available
//                                         </td>
//                                     </tr>
//                                 )}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//                 <Pagination
//                     currentPage={currentPage}
//                     totalPages={totalPages}
//                     onPageChange={(page) => setCurrentPage(page)}
//                 />
//             </div>
//         );
//     };
//     return (
//         <div>
//             <div id="loading" className="bg-gradient-to-b from-black to-black w-full fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-full flex items-center justify-center z-50" style={{ background: 'black', display: 'none' }}>
//                 <div className="fancy-spinner">
//                     <div className="ring" />
//                     <div className="ring" />
//                     <div className="dot">
//                         <img src="images/DeFi_Coin.webp?v=1720153929" width="150px" height="150px" className="h-full w-full object-contain" alt="Defi World Logo" />
//                     </div>
//                 </div>
//             </div>
//             <div id="mobile" className="demo2 relative">
//                 <div id="mobileBodyContent">
//                     <Header />
//                     <div className="inner-content px-4 2xl:px-3 mx-auto mt-5">
//                         <section className="font-normal bg-darkBlueGray-500 text-white mt-0">
//                             <div className="container overflow-hidden mx-auto h-[auto]">
//                                 <div className="flex flex-wrap">
//                                     <div className="contentBox container mx-auto">
//                                         {/* <div action="/IncomeOverview" id="form" name="form" method="POST" className="py-5 mx-auto mb-3">
//                                             <div className="flex flex-wrap items-center justify-center">
//                                                 <div className="relative mr-3 mb-3">
//                                                     <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
//                                                         <svg aria-hidden="true" className="w-5 h-5 text-[#00ffff]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                                                             <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
//                                                         </svg>
//                                                     </div>
//                                                     <input id="startDate" name="start_date" type="text" className="w-full bg-[#000000] rounded-md text-white pl-12 pr-6 py-4 border border-[#006f76] focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none hasDatepicker" placeholder="From Date" required autoComplete="off" />
//                                                 </div>
//                                                 <div className="relative mr-3 mb-3">
//                                                     <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
//                                                         <svg aria-hidden="true" className="w-5 h-5 text-[#00ffff]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                                                             <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
//                                                         </svg>
//                                                     </div>
//                                                     <input id="endDate" name="end_date" type="text" className="w-full bg-[#000000] border rounded-md text-white pl-12 pr-6 border border-[#006f76] py-4 focus:outline-0 outline-none font-normal placeholder-white-200 focus:outline-none appearance-none hasDatepicker" placeholder="To Date" required autoComplete="off" />
//                                                 </div>
//                                                 <div className="mr-3 mb-3">
//                                                     <button className="w-full flex items-center justify-center px-8 py-4 bg-[#006f76] text-white text-lg tracking-wider font-normal rounded-md border border-transparent focus:outline-none" id="view" type="submit" name="view">
//                                                         <i className="las la-search text-[#00ffff] font-bold" />
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                         </div> */}
//                                         <div className="p-4 pb-12 mx-auto mb-[10%]">
//                                             <div className="container overflow-hidden mx-auto">
//                                                 <div className="bg-darkBlueGray-500 p-6 pb-12 rounded-3xl w-full mx-auto">
//                                                     <div className="bg-darkBlueGray-500 p-6 pb-12 rounded-3xl w-full mx-auto">
//                                                         <div className="tabsNav flex items-center justify-between border-b border-darkBlack-400 mb-8 overflow-x-auto whitespace-nowrap">
//                                                             {bonuses.map((tab) => (
//                                                                 <button
//                                                                     key={tab.value}
//                                                                     className={`flex-shrink-0 text-white text-xs font-normal tracking-wider uppercase text-center py-3 px-2 focus:outline-none border-b-2 ${activeTab.value === tab.value ? 'border-[#00ffff] text-[#00ffff]' : 'border-transparent text-gray-400'}`}
//                                                                     onClick={() => handleTabClick(tab)}
//                                                                 >
//                                                                     {tab.title}
//                                                                 </button>
//                                                             ))}
//                                                         </div>
//                                                     </div>
//                                                     {renderTable(activeTab)}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </section>
//                     </div>
//                     <Footer />
//                 </div>
//             </div>
//         </div>
//     );
// }



import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiPaths } from '../../Config/ApiPaths';
import useAxiosHelper from '../../Common/AxiosHalper';
import moment from 'moment';
import Pagination from '../../Components/PaginationControls/PaginationControls';
import Loader from '../../Components/Loader/Loader';

export default function IncomeOverview() {
    const initialBonuses = [
        { title: "Monthly Rewards", value: "roi_income" },
        { title: "Level Bonus", value: "roi_level_income" },
        { title: "Referral Bonus", value: "direct_income" },
        { title: "Rank Reward", value: "reward_income" },
    ];
    const [activeTab, setActiveTab] = useState(initialBonuses[0]);
    const [data, setData] = useState({});
    const [bonuses, setBonuses] = useState(initialBonuses);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { AxiosGet } = useAxiosHelper([]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tab = params.get('tab');
        const activeTab = bonuses.find(bonus => bonus.value === tab) || bonuses[0];
        setActiveTab(activeTab);
        setCurrentPage(1);  // Reset to first page on tab change
        fetchData(activeTab.value, 1);
    }, [location.search, bonuses]);

    useEffect(() => {
        fetchData(activeTab.value, currentPage);
    }, [currentPage, activeTab]);
    let limit = 10;
    const fetchData = async (tab, page) => {
        setLoading(true);
        try {

            let response;
            if (tab === 'reward_income') {
                response = await AxiosGet(ApiPaths.getRanks);
                setData(prevData => ({
                    ...prevData,
                    [tab]: response || []
                }));
            } else {
                const queryParams = { source: tab, limit: limit, page };
                const queryString = new URLSearchParams(queryParams).toString();
                response = await AxiosGet(`${ApiPaths.getPaymentTransaction}?${queryString}`);
                setTotalPages(response?.totalPages || 1);
                setData(prevData => ({
                    ...prevData,
                    [tab]: response.data || []
                }));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        navigate(`?tab=${tab.value}`);
    };

    const renderTable = (tab) => {
        const tabData = data[tab.value] || [];
        const columns = {
            "roi_income": ["Amount", "From", "Level", "Date"],
            "roi_level_income": ["Amount", "From", "Level", "Date"],
            "direct_income": ["Amount", "From", "Level", "Date"],
            "reward_income": ["Rank", "Direct Sale", "Group Sale", "Rank Requirement", "Active User", "Bonus", "Status"]
        };

        return (
            <div>
                <div className="title text-lg font-medium text-white mb-3 tracking-wider">{tab.title}</div>
                {loading ? (
                    <Loader />
                ) : (
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full rounded-5xl overflow-hidden">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="text-body">
                                        <th className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left">Sr.</th>
                                        {columns[tab.value] && columns[tab.value].map((col, index) => (
                                            <th
                                                key={index}
                                                className="h-auto px-4 py-3 bg-black bg-opacity-10 text-sm font-normal whitespace-pre text-white tracking-wider text-left"
                                            >
                                                {col}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    {tabData.length > 0 ? tabData.map((item, index) => (
                                        <tr key={index} className="border-b border-gray-100 border-opacity-10 font-medium text-xs tracking-wider whitespace-pre">
                                            <td className="text-left px-4 py-3">{(currentPage - 1) * limit + index + 1}</td>
                                            {columns[tab.value].map((col, colIndex) => {
                                                let cellValue;
                                                switch (col) {
                                                    case "Rank":
                                                        cellValue = item.rankname || '-';
                                                        break;
                                                    case "Direct Sale":
                                                        cellValue = `${item?.DirectBusiness}/${item?.requiredDirectBusiness}`;
                                                        break;
                                                    case "Group Sale":
                                                        cellValue = `${item?.business}/${item?.required_business}`;
                                                        break;
                                                    case "Rank Requirement":
                                                        cellValue = item?.required_ranks
                                                            ? `${item.required_ranks} x ${item.requiredRankCount}`
                                                            : "-";
                                                        break;
                                                    case "Active User":
                                                        cellValue = `${item.directReferrals} / ${item.requiredDirect}`;
                                                        break;
                                                    case "Bonus":
                                                        cellValue = item.reward_income || '-';
                                                        break;
                                                    case "Status":
                                                        cellValue = item.status === 1 ? 'Achieved' : 'Pending';
                                                        break;
                                                    case "Amount":
                                                        cellValue = item.amount ? item.amount.toFixed(2) : '0';
                                                        break;
                                                    case "Date":
                                                        cellValue = moment(item.time).format("Do MMM YY");
                                                        break;
                                                    default:
                                                        cellValue = item[col.replace(/\s+/g, '_').toLowerCase()] || '-';
                                                        break;
                                                }

                                                return (
                                                    <td key={colIndex} className="text-left px-4 py-3">
                                                        {cellValue}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )) : (
                                        <tr className="border-b border-gray-100 border-opacity-10 font-medium text-xs tracking-wider whitespace-pre">
                                            <td
                                                className="text-left px-4 py-3"
                                                colSpan={(columns[tab.value] ? columns[tab.value].length : 0) + 1}
                                            >
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {tab.value !== 'reward_income' && (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                )}
            </div>
        );
    };

    return (
        <div>
            <div className="demo2 relative">
                <div id="mobileBodyContent">
                    <Header />
                    <div className="inner-content px-4 2xl:px-3 mx-auto mt-5">
                        <section className="font-normal bg-darkBlueGray-500 text-white mt-0">
                            <div className="container overflow-hidden mx-auto h-[auto]">
                                <div className="contentBox container mx-auto">
                                    <div className="p-4 pb-12 mx-auto mb-[10%]">
                                        <div className="container overflow-hidden mx-auto">
                                            <div className="bg-darkBlueGray-500 p-6 pb-12 rounded-3xl w-full mx-auto">
                                                <div className="tabsNav flex items-center justify-between border-b border-darkBlack-400 mb-8 overflow-x-auto whitespace-nowrap">
                                                    {bonuses.map((tab) => (
                                                        <button
                                                            key={tab.value}
                                                            className={`flex-shrink-0 text-white text-xs font-normal tracking-wider uppercase text-center py-3 px-2 focus:outline-none border-b-2 ${activeTab.value === tab.value ? 'border-[#00ffff] text-[#00ffff]' : 'border-transparent text-gray-400'}`}
                                                            onClick={() => handleTabClick(tab)}
                                                        >
                                                            {tab.title}
                                                        </button>
                                                    ))}
                                                </div>
                                                {renderTable(activeTab)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
